import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../common/app.constants';

const httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};

@Injectable({
  providedIn: 'root'
})
export class ThuvienService {

  constructor(private http: HttpClient) { }

  getAllThuvien(search, page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'libraryDocument/listAll?search='+ search + '&page='+ page + '&size='+ size,httpOptions);
  }
  getAllLibrary(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'library/listAll',httpOptions);
  }
  getLibraryId(id): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'library/' + id,httpOptions);
  }
  getThuvienId(id): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'libraryDocument/' + id,httpOptions);
  }

  addThuvien(data): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'libraryDocument/add',data ,httpOptions);
  }
  deleteThuvienById(id): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'libraryDocument/delete/'+id ,httpOptions);
  }
  updateThuvien(data): Observable<any> {
    return this.http.put(AppConstants.API_URL + 'libraryDocument/update',data ,httpOptions);
  }

}
