import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Tài khoản',
    url: 'tai-khoan',
    icon: 'bi bi-person-fill'
  },
  {
    name: 'Tài liệu',
    url: 'tai-lieu',
    icon: 'bi bi-file-earmark-fill'
  },
  {
    name: 'Đề thi',
    url: 'de-thi',
    icon: 'bi bi-journal-text'
  },
  {
    name: 'Nhóm',
    url: 'nhom-don-vi',
    icon: 'bi bi-people-fill'
  },
  {
    name: 'Cuộc thi',
    url: 'cuoc-thi',
    icon: 'bi bi-mortarboard-fill'
  },
  {
    name: 'Bài học',
    url: 'lop-hoc/bai-hoc',
    icon: 'bi bi-file-earmark-fill'
  },
  {
    name: 'Bài tập',
    url: 'lop-hoc/bai-tap',
    icon: 'bi bi-journal-text'
  },
  {
    name: 'Lớp học',
    url: 'lop-hoc/lop-hoc',
    icon: 'bi bi-easel2-fill'
  },
  {
    name: 'Trộn đề',
    url: 'lop-hoc/tron-de-thi',
    icon: 'bi bi-shuffle'
  },
  {
    name: 'Lớp học',
    url: 'hoc-vien/lop-hoc',
    icon: 'bi bi-easel2-fill'
  },
  {
    name: 'Lịch sử',
    url: 'lich-su',
    icon: 'bi bi-clock-history'
  },
  {
    name: 'Thống kê',
    url: 'thong-ke',
    icon: 'bi bi-clock-history'
  }
];
