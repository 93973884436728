// using for localhost
// export const IP = 'localhost:8086'
// export class AppConstants {
//     private static API_BASE_URL = 'http://' + IP + '/';
//     private static API_BASE_URL2 = 'http://' + IP;
//     private static OAUTH2_URL = AppConstants.API_BASE_URL + "oauth2/authorization/";
//     private static REDIRECT_URL = '?redirect_uri=http://192.168.0.105:8081/login';
//     public static API_URL = AppConstants.API_BASE_URL + "api/";
//     public static API_URL2 = AppConstants.API_BASE_URL2;
//     public static AUTH_API = AppConstants.API_URL + "auth/";
//     public static GOOGLE_AUTH_URL = AppConstants.OAUTH2_URL + "google" + AppConstants.REDIRECT_URL;
//     public static FACEBOOK_AUTH_URL = AppConstants.OAUTH2_URL + "facebook" + AppConstants.REDIRECT_URL;
//     public static GITHUB_AUTH_URL = AppConstants.OAUTH2_URL + "github" + AppConstants.REDIRECT_URL;
//     public static LINKEDIN_AUTH_URL = AppConstants.OAUTH2_URL + "linkedin" + AppConstants.REDIRECT_URL;
// }

// using for Production
export const IP = 'hoctaplyluan.tinhdoandongnai.com:9096'
export class AppConstants {
    private static API_BASE_URL = 'https://' + IP + '/';
    private static API_BASE_URL2 = 'https://' + IP;
    private static OAUTH2_URL = AppConstants.API_BASE_URL + "oauth2/authorization/";
    private static REDIRECT_URL = '?redirect_uri=http://192.168.0.105:8081/login';
    public static API_URL = AppConstants.API_BASE_URL + "api/";
    public static API_URL2 = AppConstants.API_BASE_URL2;
    public static AUTH_API = AppConstants.API_URL + "auth/";
    public static GOOGLE_AUTH_URL = AppConstants.OAUTH2_URL + "google" + AppConstants.REDIRECT_URL;
    public static FACEBOOK_AUTH_URL = AppConstants.OAUTH2_URL + "facebook" + AppConstants.REDIRECT_URL;
    public static GITHUB_AUTH_URL = AppConstants.OAUTH2_URL + "github" + AppConstants.REDIRECT_URL;
    public static LINKEDIN_AUTH_URL = AppConstants.OAUTH2_URL + "linkedin" + AppConstants.REDIRECT_URL;
}