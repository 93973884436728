export class LectureQuizAnswer {
    id?: number
    name?: string
    position?: number
    rightWrong?: string
    lectureQuizQuestionId?: number
    selected?: boolean;
    isAnswer?: boolean;

    constructor(data: any) {
        data = data || {};
        this.id = data.id;
        this.lectureQuizQuestionId = data.lectureQuizQuestionId;
        this.name = data.name;
        this.isAnswer = data.rightWrong === 'RIGHT' ? true : false ;
        this.selected = false;
    }
}
