import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppConstants } from '../../../common/app.constants';
import { TokenStorageService } from '../../../_services/token-storage.service';
import * as ckCustomBuild from '../../../ckCustomBuild/build/ckEditor';
import { ContestService } from '../../../_services/contest.service';
import { LearnService } from '../../../_services/learn/learn.service';
import { Learn } from '../../../../models/learn/learn';
import { LearnRating } from '../../../../models/learn/learn-rating';
import { UserService } from '../../../_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LearnStudentService } from '../../../_services/learn/learn-student.service';
import { ppid } from 'process';

@Component({
  templateUrl: 'khoa-hoc-detail.component.html',
  styleUrls: ['khoa-hoc-detail.css']
})
export class KhoaHocDetailComponent {

  @ViewChild('notifyUpdateModals') public notifyUpdateModals!: ModalDirective;
  public Editor = ckCustomBuild;
  @Input() readonly: boolean = true;
  learn: Learn = {};
  avatar: string;
  form: any = {};
  isLoggedIn = false;
  roles: string[];
  showAdminBoard = false;
  showHuyenTPBoard = false;
  isHomeFailed = false;
  passedLearn: boolean = false;
  commentRating = '';
  errorMessage = '';
  currentUser: any;
  username: string;
  userIdLoggin: any
  showRatingOfUser: boolean = true;
  searchText: any
  slug: any
  codeContest = '';
  wrong = false;
  sumOneStar: number = 0
  sumTwoStar: number = 0
  sumThreeStar: number = 0
  sumFourStar: number = 0
  sumFiveStar: number = 0
  totalStar: number = 0;
  totalTimeSeconds: number = 0;
  totalLectureDetail: number = 0;
  numberStudentOfLearn: number = 0;
  numberStarLearnRatingPassed: number = 5;

  maxSize: number = 5;
  bigTotalItems: number = 51;
  bigCurrentPage: number = 1;
  numPages: number = 0;
  backendOrgin = AppConstants.API_URL2
  learnRatingPageSize: LearnRating[] = []
  public config = {
    toolbar: {
      items: []
    },
  }
  public hideContent: boolean[] = [];
  showCommentRating: boolean = false;
  loadingJoin: boolean = false;

  constructor(private tokenStorage: TokenStorageService, private route: ActivatedRoute,
    private learnService: LearnService, private router: Router, private contestService: ContestService,
    private userService: UserService, private toastr: ToastrService, private learnStudentService: LearnStudentService) {
      this.route.params.subscribe(params => {
        if(params['slug']){
          this.slug = params['slug'];
        }
      });
  }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorage.getToken();
    if (this.isLoggedIn) {
      const user = this.tokenStorage.getUser();
      this.username = user.displayName;
      this.userIdLoggin = user.id
      this.avatar = AppConstants.API_URL2 + user.avatar;
      this.roles = user.roles;
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showHuyenTPBoard = this.roles.includes('ROLE_HUYENTP');
    }
    this.getLearnBySlug(this.slug);
  }

  onSubmit(): void {
    
  }

  toggle(index) {
    this.hideContent[index] = !this.hideContent[index];
  }

  onChangePage(pageOfItems: Array<any>) {
    this.learnRatingPageSize = pageOfItems;
  }

  getLearnBySlug(slug){
    this.learnService.getLearnBySlug(slug).pipe(map((data: any) => data.resultData)).subscribe(
      data => {
        if(data === 'Does not exist'){
          this.router.navigate(['/']);
        } else {
          this.learn = data;
          let totalSum = 0;
          this.learnRatingPageSize = this.learn.learnRating
          this.learn.learnRating.map((rating) => {
            if(rating.userId == this.userIdLoggin){
              this.showRatingOfUser = false;
            }
            totalSum += parseInt(rating.points);
            let ratingPoint = parseInt(rating.points);
            if(ratingPoint == 1){
              this.sumOneStar += 1
            } else if (rating.points == '2') {
              this.sumTwoStar += 1
            } else if (rating.points == '3') {
              this.sumThreeStar += 1
            } else if (rating.points == '4') {
              this.sumFourStar += 1
            } else if (rating.points == '5') {
              this.sumFiveStar += 1
            }
          })
          this.learn.lecture.map((lecture) => {
            lecture.lectureDetail.map((lD) => {
              if(lD.lectureType == 'VIDEO'){
                this.totalTimeSeconds += this.convertToSeconds(lD.lectureTime)
              }
              this.totalLectureDetail++;
            })
          })
          this.totalStar = this.sumOneStar + this.sumTwoStar + this.sumThreeStar + this.sumFourStar + this.sumFiveStar
          this.learn.learnRatingNumber = totalSum/this.learn.learnRating.length || 0;
          console.log(this.sumOneStar);
          this.getNumberStudentOfLearn(this.learn.id);
          if(this.isLoggedIn){
            this.getPassedLearnStudent(this.learn.id);
          }
        }
      }, err => {

      }
    )
  }

  findContest() {
    if(this.codeContest){
      this.contestService.getSlugByCode(this.codeContest).subscribe(
        data => {
          if(!data.error){
            this.router.navigateByUrl('/contest/' + data.resultData)
          } else {
            this.wrong = true;
          }
        }, err => {

        }
      )
    }
  }

  clean_url(s) {
    return s.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, "") //remove diacritics
      .toLowerCase()
      .replace(/\s+/g, '-') //spaces to dashes
      .replace(/&/g, '-and-') //ampersand to and
      .replace(/[^\w\-]+/g, '') //remove non-words
      .replace(/\-\-+/g, '-') //collapse multiple dashes
      .replace(/^-+/, '') //trim starting dash
      .replace(/-+$/, ''); //trim ending dash
  }

  logout(): void {
    this.tokenStorage.signOut();
    window.location.reload();
  }

  roundPercent = function(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor || 0;
  };

  convertToSeconds(hms) {
    const [hours, minutes, seconds] = hms.split(':');
    return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
  }

  secondsToHms(second) {
    second = Number(second);
    var h = Math.floor(second / 3600);
    var m = Math.floor(second % 3600 / 60);
    var s = Math.floor(second % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " giờ " : " giờ ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " phút" : " phút") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " giây" : " giây") : "";
    // return hDisplay + mDisplay + sDisplay; 
    return hDisplay + mDisplay; 
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth', block: 'start',inline: 'start'});
  }

  checkProfileUser(){
    this.userService.checkProfileUser().subscribe(
      response => {
        if(response.error){
          this.notifyUpdateModals.show()
        } else {
          this.addLearnStudent();
        }
      }, err => {
        this.toastr.error('Có lỗi trong quá trình xử lý!', 'Thất bại')
      }
    )
  }

  getPassedLearnStudent(learnId){
    this.learnStudentService.getPassedLearnStudent(learnId).subscribe(
      response => {
        this.passedLearn = response;
      }, err => {
        this.toastr.error('Có lỗi trong quá trình xử lý!', 'Thất bại')
      }
    )
  }
    
  addLearnStudent(){
    this.learnStudentService.addLearnStudent(this.learn.id).subscribe(
      response => {
        if(response.error){
          this.toastr.error(response.resultData)
        } else {
          this.loadingJoin = true;
          setTimeout(()=>{
            let urlRedirectLearn = 'khoa-hoc/' + this.learn.id + '/lecture/' + this.learn.lecture[0]?.id + '/' + this.learn.lecture[0]?.lectureDetail[0]?.id;
            this.router.navigateByUrl(urlRedirectLearn);
          }, 2000)
        }
      }
    )
  }

  getNumberStudentOfLearn(learnId){
    this.learnStudentService.getNumberStudentOfLearn(learnId).subscribe(
      response => {
        this.numberStudentOfLearn = response;
      }, err => {
        this.toastr.error('Có lỗi trong quá trình xử lý!', 'Thất bại')
      }
    )
  }

  sendLearnRating(){
    const data = {
      points: this.numberStarLearnRatingPassed,
      learnId: this.learn.id,
      comment: this.commentRating
    }
    this.learnStudentService.sendLearnRating(data).subscribe(
      response => {
        if(response.error){
          this.toastr.error(response.errorMessage, 'Thất bại')
        } else {
          this.toastr.success(response.resultData,'Thành công!')
          this.ngOnInit();
        }
      }, err => {
        this.toastr.error('Có lỗi trong quá trình xử lý!', 'Thất bại')
      }
    )
  }
}
