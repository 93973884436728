import { Component, ElementRef, Input, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { map } from 'rxjs/operators';
import { Contest } from '../../../models/contest';
import { ContestService } from '../../_services/contest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../../common/app.constants';
import { interval} from 'rxjs';
import { TokenStorageService } from '../../_services/token-storage.service';
import { ResultContest } from '../../../models/resultContest';
import { ResultContestService } from '../../_services/result-contest.service';
import { ModalService } from '../../_services/model.service';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { BLogService } from '../../_services/blog.service';
import { Blog } from '../../../models/blog';
import { ToastrService } from 'ngx-toastr';
import { style } from '@angular/animations';
import { KeyValue } from '@angular/common';
import * as ckCustomBuild from '../../ckCustomBuild/build/ckEditor';

@Component({
  templateUrl: 'contestonline-view.component.html',
  styleUrls: ['contestonline-view.css']
})
export class ContestOnlineViewComponent {
  
  @ViewChild('autoShownModal', { static: false }) autoShownModal?: ModalDirective;
  isModalShown = false;
  objectKeys = Object.keys;
  public config = {
    toolbar: {
      items: []
    },    
  }
  public Editor = ckCustomBuild;
  @Input() readonly: boolean = true;

  avatar: string;
  slug: any;
  contest: Contest = {
    candidatesInfomation: {},
    rankInfomation: {},
    contestSettingQuiz: {},
    organization: {}
  };
  start: any;
  end: any;
  startDate: any;
  endDate: any;
  remaining:any;
  text: any
  wrong = false
  startContest: boolean = false;
  finish: boolean = false;
  resultContest: ResultContest = {
    nameMember: null,
    emailMember: null,
    emailLogin: null,
    phoneMember: null,
    phoneLogin: null,
    cccdCmnd: null,
    organizationName: null,
    classCodeWorkplace: null,
    dateOfBirth: null,
    gender: null,
    job: null,
    timeExam: null,
    dateSubmit: null,
    titleExam: null,
    contestId: null
  };
  blogs: Blog[] = [];
  _diff: number;
  _days: number;
  _hours: number;
  _minutes: number;
  _seconds: number;
  isLoggedIn = false;
  username: string;
  jobs: any =[
    'Giáo viên - Giảng viên',
    'Học viên - Sinh viên',
    'Nhân viên văn phòng',
    'Đào tạo nghiệp vụ - Huấn luyện kỹ năng',
    'Cán bộ công chức',
    'Công nhân - Lao động',
    'Nội trợ','Khác'
  ]
  password: any
  correctPassword: any
  resultIdState: any
  numberCorrect: any;
  nameMember: any
  timeExam: any;
  percent: number
  codeContest = '';
  roles: string[];
  showAdminBoard = false;
  showModeratorBoard = false;

  numSubcribed = 0
  subcribed: any = {}
  numContested = 0
  contested: any = {}
  thisWeekRank: ResultContest[] = [];
  finalRank: ResultContest[] = [];

  hideBtnSubcribe = true;
  textLoadMoreSubcribed = 'Xem thêm'
  hideBtnContested = true;
  textLoadMoreContested = 'Xem thêm'
  hideBtnRankWeek = true;
  textLoadMoreRankWeek = 'Xem thêm'
  hideBtnRankFinal = true;
  textLoadMoreRankFinal = 'Xem thêm'
  originBackend = AppConstants.API_URL2

  emailPartten = "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  phonePartten = "^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$";
  cmndPartten = "[0-9]{9,12}";
  displayNamePartten = "^[AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬBCDĐEÈẺẼÉẸÊỀỂỄẾỆFGHIÌỈĨÍỊJKLMNOÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢPQRSTUÙỦŨÚỤƯỪỬỮỨỰVWXYỲỶỸÝỴZ][aàảãáạăằẳẵắặâầẩẫấậbcdđeèẻẽéẹêềểễếệfghiìỉĩíịjklmnoòỏõóọôồổỗốộơờởỡớợpqrstuùủũúụưừửữứựvwxyỳỷỹýỵz]+ [AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬBCDĐEÈẺẼÉẸÊỀỂỄẾỆFGHIÌỈĨÍỊJKLMNOÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢPQRSTUÙỦŨÚỤƯỪỬỮỨỰVWXYỲỶỸÝỴZ][aàảãáạăằẳẵắặâầẩẫấậbcdđeèẻẽéẹêềểễếệfghiìỉĩíịjklmnoòỏõóọôồổỗốộơờởỡớợpqrstuùủũúụưừửữứựvwxyỳỷỹýỵz]+(?: [AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬBCDĐEÈẺẼÉẸÊỀỂỄẾỆFGHIÌỈĨÍỊJKLMNOÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢPQRSTUÙỦŨÚỤƯỪỬỮỨỰVWXYỲỶỸÝỴZ][aàảãáạăằẳẵắặâầẩẫấậbcdđeèẻẽéẹêềểễếệfghiìỉĩíịjklmnoòỏõóọôồổỗốộơờởỡớợpqrstuùủũúụưừửữứựvwxyỳỷỹýỵz]*)*"
  isError: boolean = false;
  messageError: string = '';

  constructor(private contestService: ContestService, private route: ActivatedRoute,  private router: Router,
    private tokenStorageService: TokenStorageService, private resultContestService: ResultContestService,
    private blogService: BLogService, private toastr: ToastrService){
      this.route.params.subscribe(params => {
        if(params['slug']){
          this.slug = params['slug'];
        }
      });
      this.route.queryParams.subscribe(params => {
        this.resultIdState = params['resultId'];
      })
  }

  ngOnInit(): void {
    if(this.resultIdState !== undefined){
      this.getResultContestById(this.resultIdState);
    }
    this.getContestBySlug(this.slug);
    this.isLoggedIn = !!this.tokenStorageService.getToken();
    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.username = user.displayName;
      this.resultContest.nameMember = this.username
      this.avatar = AppConstants.API_URL2 + user.avatar;
      this.roles = user.roles;
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');
    }
  }

  hideModal(): void {
    this.autoShownModal?.hide();
  }
 
  onHidden(): void {
    this.isModalShown = false;
  }

  onSubmit(){
    this.resultContest.contestId = this.contest.id;
    if(this.contest.candidatesInfomation.password){  
      const passwords = {
        password: this.password
      }
      this.contestService.checkPassword(passwords,this.slug).subscribe(
        data => {
          this.correctPassword = data.resultData;
          if(data.resultData === 'ok'){
            this.resultContestService.addResultContest(this.resultContest).subscribe(
              data => {
                if(data.error){
                  this.toastr.warning(data.errorMessage)
                } else {
                  this.router.navigateByUrl('/contest/'+this.slug + '/thi', {state: data.resultData.id})
                }
              }, err => {

              }
            )
          } else {
            return;
          }
        }, err => {
        }
      )
    } else {
      this.resultContestService.addResultContest(this.resultContest).subscribe(
        data => {
          if(data.error){
            this.toastr.warning(data.errorMessage)
          } else {
            this.router.navigateByUrl('/contest/'+this.slug + '/thi', {state: data.resultData.id})
          }
        }, err => {

        }
      )
    }
  }
  
  findContest(){
    if(this.codeContest){
      this.contestService.getSlugByCode(this.codeContest).subscribe(
        data => {
          if(!data.error){
            this.router.navigateByUrl('/contest/' + data.resultData)
          } else {
            this.wrong = true
          }
        }, err => {
        }
      )
    }
  }

  getResultContestById(resultContestId){
    this.resultContestService.getResultContestByIdOfCorrectNumber(resultContestId).subscribe(
      data => {
        var result = JSON.parse(data.resultData);
        this.numberCorrect = result.correctNumber
        this.timeExam = result.timeExam;
        this.percent = result.percent;
        this.nameMember = result.nameMember;
        this.isModalShown = true
      }, err => {
      }
    )
  }
    
  keyDescOrder = (a: KeyValue<string,number>, b: KeyValue<string, number>): number => {
    return a.value > b.value ? -1 : (a.value > b.value ? 1 : 0);
  }

  getContestBySlug(slug){
    this.contestService.getContestBySlug(slug).subscribe(
      response => {
        if(response.error){
          this.isError = true
          this.messageError = response.errorMessage
        } else {
          this.contest = response.resultData.contest;
          this.contest.imageBanner = AppConstants.API_URL2 + this.contest.imageBanner
          var contestStartTime = new Date(this.contest.contestStartTime)
          this.start = this.twoDigitString(contestStartTime.getHours()) + 'h' + this.twoDigitString(contestStartTime.getMinutes());
          this.startDate = contestStartTime.getDate() + '/' + (contestStartTime.getMonth()+1) + '/' + contestStartTime.getFullYear();
          var contestEndTime = new Date(this.contest.contestEndTime)
          this.end = this.twoDigitString(contestEndTime.getHours()) + 'h' + this.twoDigitString(contestEndTime.getMinutes());
          this.endDate = contestEndTime.getDate() + '/' + (contestEndTime.getMonth()+1) + '/' + contestEndTime.getFullYear();
          this.calculatorTime(contestStartTime, contestEndTime);
          this.contest.blog.map(i => {
            i.slug = this.clean_url(i.title) + '-' + i.id;
          })

          this.subcribed = response.resultData.subcribed;
          Object.values(this.subcribed).map(s => {
            this.numSubcribed += Number(s);
          })
          this.contested = response.resultData.contested;
          Object.values(this.contested).map(s => {
            this.numContested += Number(s);
          })
          this.thisWeekRank = response.resultData.thisWeekRank;
          this.finalRank = response.resultData.finalRank;

          // var dt = new Date(); // current date of week
          // var currentWeekDay = dt.getDay();
          // var lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;
          // var wkStart = new Date(new Date(dt).setDate(dt.getDate() - lessDays));
          // var wkEnd = new Date(new Date(wkStart).setDate(wkStart.getDate() + 6));
          
          // // this.contest.resultContest.sort((a,b) => b.percent - a.percent)
          // this.contest.resultContest.sort((a,b) => b.percent - a.percent? b.percent - a.percent: b.timeExam > a.timeExam ? 1 : -1)
          // // this.contest.resultContest.sort((a,b) => a.percent === b.percent? a.timeExam - b.timeExam: b.percent > a.percent ? 1 : -1)
          // this.contest.resultContest.map((result) => {
          //   if(result.organizationName == null){
          //     result.organizationName = 'Chưa phân đơn vị'
          //   }
          //   if(!result.timeExam){
          //     if(this.contest.rankInfomation.candidatesSub){
          //       this.subcribed[result.organizationName] = this.subcribed[result.organizationName] || [];
          //       this.subcribed[result.organizationName].push(result.nameMember)
          //       this.numSubcribed++
          //     }
          //   } else {
          //     if(this.contest.rankInfomation.candidatesJoin){
          //       this.contested[result.organizationName] = this.contested[result.organizationName] || [];
          //       this.contested[result.organizationName].push(result.nameMember)
          //       this.numContested++
          //     }
          //   }
          // })
          // // console.log(this.contest.resultContest)
          // this.contest.resultContest.filter((value, index, self) => self.findIndex((m) => m.userId === value.userId) === index).map((result)=>{
          //   if(this.contest.rankInfomation.rankWeekFinal){
          //     if(result.timeExam){
          //         this.finalRank.push(result)
          //     }
          //   }
          // })
          // this.contest.resultContest.sort((a,b) => b.percent - a.percent? b.percent - a.percent: b.timeExam > a.timeExam ? 1 : 
          //     new Date(b.dateSubmit).getTime() - new Date(a.dateSubmit).getTime())
          //       .filter((value, index, self) => self.findIndex((m) => m.userId === value.userId) === index).map((result)=>{
          //   if(this.contest.rankInfomation.rankWeekFinal){
          //     if(result.timeExam){
          //       var dateSubmit = new Date(result.dateSubmit);
          //       // console.log(result)
          //       if(dateSubmit.getTime() >= wkStart.getTime() && dateSubmit.getTime() <= wkEnd.getTime()){
          //           // console.log(this.thisWeekRank)
          //           this.thisWeekRank.push(result)
          //       }
          //     }
          //   }
          // })
        }
      }, err => {
        this.router.navigate(['/contest']);
      }
    )
  }

  calculatorTime(contestStartTime, contestEndTime){
    if(new Date().getTime() < contestStartTime.getTime()){
      this.text = 'Bắt đầu sau';
      interval(1000).pipe(
        map((x) => {this._diff = contestStartTime.getTime() - Date.parse(new Date().toString());
          })).subscribe((x) => {
              this._days = this.getDays(this._diff);
              this._hours = this.getHours(this._diff);
              this._minutes = this.getMinutes(this._diff);
              this._seconds = this.getSeconds(this._diff);
              if(this._diff == 0){
                this.calculatorTime(contestStartTime, contestEndTime)
              }
          });
    } else {
      this.text = 'KẾT THÚC sau';
      if(new Date().getTime() > contestEndTime.getTime()){
        this.finish = true
      } else {
        interval(1000).pipe(
          map((x) => {this._diff = contestEndTime.getTime() - Date.parse(new Date().toString());
            })).subscribe((x) => {
                this._days = this.getDays(this._diff);
                this._hours = this.getHours(this._diff);
                this._minutes = this.getMinutes(this._diff);
                this._seconds = this.getSeconds(this._diff);
                this.startContest = true
                if(this._diff == 0){
                  this.finish = true
                  this.startContest = false
                }
            });
      }
    }
  }

  onItemChange(value){
    this.resultContest.gender = value;
  }

  getDays(t) {
    return Math.floor( t / (1000 * 60 * 60 * 24) );
  }
  getHours(t) {
      return Math.floor( (t / (1000 * 60 * 60)) % 24 );
  }
  getMinutes(t) {
      return Math.floor( (t / 1000 / 60) % 60 );
  }
  getSeconds(t) {
      return Math.floor( (t / 1000) % 60 );
  }

  clean_url(s) {
    return s.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, "") //remove diacritics
            .toLowerCase()
            .replace(/\s+/g, '-') //spaces to dashes
            .replace(/&/g, '-and-') //ampersand to and
            .replace(/[^\w\-]+/g, '') //remove non-words
            .replace(/\-\-+/g, '-') //collapse multiple dashes
            .replace(/^-+/, '') //trim starting dash
            .replace(/-+$/, ''); //trim ending dash
  }

  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
  }
  
  twoDigitString(text){
    if(text < 10){
      return '0' + text;
    } else {
      return text;
    }
  }

  loadMore(text){
    if(text == 'subcribed'){
      const elementList = document.querySelectorAll('.numSubcribed .list-element');
      if(this.hideBtnSubcribe){
        this.textLoadMoreSubcribed = 'Thu gọn'
        this.hideBtnSubcribe = false;
        elementList.forEach((element)=>{
          element.setAttribute('style', 'display:block; white-space: initial;')
        })
      } else {
        this.hideBtnSubcribe = true;
        this.textLoadMoreSubcribed = 'Xem thêm'
        elementList.forEach((element, i)=>{
          if(elementList.length > 3){
            if(i > 3){
              element.setAttribute('style', 'display:none')
            }
          }
        })
      }
    } else if (text == 'contested'){
      const elementList = document.querySelectorAll('.numContested .list-element');
      if(this.hideBtnContested){
        this.textLoadMoreContested = 'Thu gọn'
        this.hideBtnContested = false;
        elementList.forEach((element)=>{
          element.setAttribute('style', 'display:block; white-space: initial;')
        })
      } else {
        this.hideBtnContested = true;
        this.textLoadMoreContested = 'Xem thêm'
        elementList.forEach((element, i)=>{
          if(elementList.length > 3){
            if(i > 3){
              element.setAttribute('style', 'display:none')
            }
          }
        })
      }
    } else if (text == 'rankWeek'){
      const elementList = document.querySelectorAll('.rankWeek .list-element');
      if(this.hideBtnRankWeek){
        this.textLoadMoreRankWeek = 'Thu gọn'
        this.hideBtnRankWeek = false;
        elementList.forEach((element)=>{
          element.setAttribute('style', 'display:flex')
        })
      } else {
        this.hideBtnRankWeek = true;
        this.textLoadMoreContested = 'Xem thêm'
        elementList.forEach((element, i)=>{
          if(elementList.length > 3){
            if(i > 3){
              element.setAttribute('style', 'display:none')
            }
          }
        })
      }
    } else {
      const elementList = document.querySelectorAll('.rankFinal .list-element');
      if(this.hideBtnRankFinal){
        this.textLoadMoreRankFinal = 'Thu gọn'
        this.hideBtnRankFinal = false;
        elementList.forEach((element)=>{
          element.setAttribute('style', 'display: flex')
        })
      } else {
        this.hideBtnRankFinal = true;
        this.textLoadMoreContested = 'Xem thêm'
        elementList.forEach((element, i)=>{
          if(elementList.length > 3){
            if(i > 3){
              element.setAttribute('style', 'display:none')
            }
          }
        })
      }
    }
  }

  secondsToHms(second) {
    second = Number(second);
    var h = Math.floor(second / 3600);
    var m = Math.floor(second % 3600 / 60);
    var s = Math.floor(second % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " giờ, " : " giờ, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " phút, " : " phút, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " giây" : " giây") : "";
    return hDisplay + mDisplay + sDisplay; 
}

}
