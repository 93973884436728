import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../common/app.constants';

const httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient) { }

  getNewsByCurrentUser(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'news/byCurrentUser',httpOptions);
  }

  getNewsByCurrentUserListAll(search, page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'news/byCurrentUser/listAll?search='+ search + '&page='+ page + '&size='+ size,httpOptions);
  }
  getNewsByHasActiveListAll(search, page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'news/byNoActive/listAll?search='+ search + '&page='+ page + '&size='+ size,httpOptions);
  }

  getNewsLatest(search, page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'news/list?search='+ search +'&page=' + page + '&size=' + size,httpOptions);
  }

  getNewsId(id): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'news/' + id,httpOptions);
  }

  getNewsBySlug(slug): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'news/slug/' + slug,httpOptions);
  }

  getNewsRelated(newsId): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'news/list/related?notEqual=' + newsId, httpOptions);
  }

  getNewsByCurrentUserAndContestIsNull(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'news/byCurrentUser/contestIsNull', httpOptions)
  }

  // getNewsByContestIdNoPermission(contestId): Observable<any> {
  //   return this.http.get(AppConstants.API_URL + 'News/byContestId/noPermission/'+ contestId, httpOptions)
  // }

  getNewsByCurrentUserAndContestId(contestId): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'news/byCurrentUser/contestId/' + contestId, httpOptions)
  }

  addNews(data): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'news/add',data ,httpOptions);
  }
  
  addNewsHuyenTP(data): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'news/huyentp/add',data ,httpOptions);
  }

  deleteNewsById(id): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'news/delete/'+id ,httpOptions);
  }

  updateNews(data): Observable<any> {
    return this.http.put(AppConstants.API_URL + 'news/update',data ,httpOptions);
  }

  updateNewsHuyenTP(data): Observable<any> {
    return this.http.put(AppConstants.API_URL + 'news/huyentp/update',data ,httpOptions);
  }
  
}
