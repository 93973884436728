import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../../common/app.constants';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class LectureDetailCommentService {

  constructor(private http: HttpClient) { }

  addLectureDetailComment(data): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'lectureDetailComment/add',data ,httpOptions);
  }

  addLectureDetailCommentReply(data): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'lectureDetailCommentReply/add',data ,httpOptions);
  }
  
}
