import { Component } from '@angular/core';
import { AppConstants } from '../../common/app.constants';
import { AuthService } from '../../_services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'forgot-password.component.html'
})
export class ForgotPasswordComponent { 

  email: any
  showPassword = false;
  form: any = {};
  currentUser: any;

  constructor(private authService: AuthService, private toastr: ToastrService) {}

  ngOnInit(): void {
    
  }

  onSubmit(): void {
    this.authService.forgotPassword(this.email).subscribe(
      response => {
        if (response.error) {
          this.toastr.error(response.errorMessage);
        } else {
          this.toastr.success(response.resultData);
        }
      },
      err => {}
    );
  }

}
