import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../../common/app.constants';

const httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};

@Injectable({
  providedIn: 'root'
})
export class LearnService {

  constructor(private http: HttpClient) { }

  getLearnByCurrentUser(search, page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'learn/byCurrentUser?search='+ search + '&page='+ page + '&size='+ size,httpOptions);
  }

  getLearnByCurrentUserListAll(search, page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'learn/byCurrentUser/listAll?search='+ search + '&page='+ page + '&size='+ size,httpOptions);
  }
  
  getLearnByHasActiveListAll(search, page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'learn/byHide/listAll?search='+ search + '&page='+ page + '&size='+ size,httpOptions);
  }

  getLearnLatest4(search, page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'learn/list?search='+ search +'&page=' + page + '&size=' + size,httpOptions);
  }

  getLearnId(id): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'learn/' + id,httpOptions);
  }

  getLearnIdByCurrentUser(id): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'learn/' + id + '/byCurrentUser' ,httpOptions);
  }
  
  getLearnBySlug(slug): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'learn/slug/' + slug,httpOptions);
  }

  uploadLearn(learn: any): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'learn/update', learn, httpOptions);
  }

  createLearn(learn: any): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'learn/add', learn, httpOptions);
  }

  uploadVideo(formData): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'upload/ffmpeg', formData);
  }

  updateHideOrCertificate(data): Observable<any> {
    return this.http.put(AppConstants.API_URL + 'learn/updateHideOrCertificate', data);
  }
  
}
