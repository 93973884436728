import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../common/app.constants';

const httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getPublicContent(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'all', { responseType: 'text' });
  }

  getUserBoard(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'user', { responseType: 'text' });
  }

  getModeratorBoard(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'mod', { responseType: 'text' });
  }

  getAdminBoard(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'admin', { responseType: 'text' });
  }

  getCurrentUser(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'user/me', httpOptions);
  }

  getCurrentUser2(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'user/me2', httpOptions);
  }

  getAllUser(search, page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'user/listAll?search=' + search + '&page=' + page + '&size=' + size, httpOptions);
  }

  getAllUserByName(search, page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'user/listAllByName?search=' + search + '&page=' + page + '&size=' + size, httpOptions);
  }

  updateUser(profile): Observable<any> {
    return this.http.put(AppConstants.API_URL + 'user/update',profile,httpOptions);
  }
  
  updateUserRole(user): Observable<any> {
    return this.http.put(AppConstants.API_URL + 'user/update/role',user,httpOptions);
  }

  lockUser(user): Observable<any> {
    return this.http.put(AppConstants.API_URL + 'user/lock',user,httpOptions);
  }

  checkProfileUser(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'user/checkProfileUser', httpOptions);
  }

  sendVerifyEmail(): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'user/sendVerificationEmail', httpOptions);
  }

  checkVerifyEmail(data): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'user/checkVerifyEmail', data, httpOptions);
  }

  checkChangePassword(data): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'user/checkChangePassword', data, httpOptions);
  }
}
