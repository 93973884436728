import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../common/app.constants';

const httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};

@Injectable({
  providedIn: 'root'
})
export class BLogService {

  constructor(private http: HttpClient) { }

  getBlogByCurrentUser(search, page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'blog/byCurrentUser?search='+ search + '&page='+ page + '&size='+ size,httpOptions);
  }

  getAllBlog(page: any, size: any): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'blog/list?page=' + page + '&size=' + size,httpOptions);
  }

  getBlogId(id): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'blog/' + id,httpOptions);
  }

  getBlogBySlug(slug): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'blog/slug/' + slug,httpOptions);
  }

  getBlogByCurrentUserAndContestIsNull(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'blog/byCurrentUser/contestIsNull', httpOptions)
  }

  // getBlogByContestIdNoPermission(contestId): Observable<any> {
  //   return this.http.get(AppConstants.API_URL + 'blog/byContestId/noPermission/'+ contestId, httpOptions)
  // }

  getBlogByCurrentUserAndContestId(contestId): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'blog/byCurrentUser/contestId/' + contestId, httpOptions)
  }

  addBlog(data): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'blog/add',data ,httpOptions);
  }

  deleteBlogById(id): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'blog/delete/'+id ,httpOptions);
  }

  updateBlog(data): Observable<any> {
    return this.http.put(AppConstants.API_URL + 'blog/update',data ,httpOptions);
  }
  
  // getCurrentUser(): Observable<any> {
  //   return this.http.get(AppConstants.API_URL + 'user/me', httpOptions);
  // }
}

@Injectable({
  providedIn: 'root'
})
export class BLogByRoomService {

  constructor(private http: HttpClient) { }

  getBlogByCurrentUserAndIgnoreExistRoom(roomId): Observable<any> {
    return this.http.get(AppConstants.API_URL + `blog/byCurrentUser/listAllIgnoreExistLessonRoom/${roomId}`, httpOptions)
  }
}