import { Component } from '@angular/core';
import { AppConstants } from '../../common/app.constants';
import { AuthService } from '../../_services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'change-password.component.html'
})
export class ChangePasswordComponent { 

  email: any
  showPassword = false;
  form: any = {};
  currentUser: any;
  userId: any;
  expries
  signature
  message = ''
  backgroundColorSucess = '#F8FAF5'
  backgroundColorError = '#faf5f5'
  h4Success = '#88B04B'
  h4Error = '#b04b4b'
  isError: boolean = true;
  passcode: any;
  newPassword: any

  constructor(private authService: AuthService, private toastr: ToastrService, private route: ActivatedRoute, private router: Router,
    private userService: UserService) {
    this.route.params.subscribe(params => {
      if(params['userId'] && !isNaN(Number(params['userId']))){
        this.userId = Number(params['userId']);
      } else {
        this.isError = false;
        this.message = 'Link xác minh không đúng định dạng';
        return;
      }
    });
    this.route.queryParams.subscribe(params => {
      if(params['expries'] && !isNaN(Number(params['expries'])) && params['signature'] && params['passcode']){
        this.expries = Number(params['expries']);
        this.signature = params['signature'];
        this.passcode = params['passcode'];
      } else {
        this.isError = false;
        this.message = 'Link xác minh không đúng định dạng';
        return;
      }
    })
  }

  ngOnInit(): void {
    
  }

  checkVerifyEmail() {
    const data = {
      userId: this.userId,
      expries: this.expries,
      signature: this.signature,
      passcode: this.passcode,
      newPassword: this.newPassword
    }
    this.userService.checkChangePassword(data).subscribe(
      response => {
        if(response.error){
          this.toastr.error(response.errorMessage)
        } else {
          this.toastr.success(response.resultData)
        }
      }, err => {}
    )
  }

}
