import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImagesLazyloadModule } from './shared/images-lazyload/images-lazyload.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { HomeComponent } from './views/home/home.component';
import { ContestOnlineViewComponent } from './views/contestonline/contestonline-view.component';
import { ContestOnlineThiComponent } from './views/contestonline/contestonline-thi.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BlogComponent } from './views/blog/blog.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FindContestComponent } from './views/findContest/findContest.component';
import { FilterPipe } from './filter.pipe';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TinTucComponent } from './views/tintuc/tintuc.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// import { EditorModule } from '@tinymce/tinymce-angular';
import { ToastrModule } from 'ngx-toastr';
import { AlertModule } from 'ngx-bootstrap/alert';
import { SafePipe } from './_safePipe/safePipe';
import { AccessRoomComponent } from './views/accessRoom/access-room.component';
import { LoaderService } from './_services/loading/loader.service';
import { MyLoaderComponent } from './views/loading/loader.component';
import { ThuVienTaiLieuComponent } from './views/thu-vien-tai-lieu/thu-vien-tai-lieu.component';
import { KhoaHocComponent } from './views/khoa-hoc/khoa-hoc.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { KhoaHocDetailComponent } from './views/khoa-hoc/khoa-hoc-detail/khoa-hoc-detail.component';
import { JwPaginationModule } from 'jw-angular-pagination';
import { KhoaHocMainComponent } from './views/khoa-hoc/khoa-hoc-main/khoa-hoc-main';
import { VerifyEmailComponent } from './views/verify-email/verify-email.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ForgotPasswordComponent } from './views/forgotPassword/forgot-password.component';
import { ChangePasswordComponent } from './views/changePassword/change-password.component';
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    ImagesLazyloadModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    IconModule,
    IconSetModule.forRoot(),
    HttpClientModule,FormsModule,
    DateTimePickerModule,
    ModalModule.forRoot(),
    // EditorModule
    AngularEditorModule,
    PaginationModule.forRoot(),
    CKEditorModule,
    ToastrModule.forRoot(),
    AlertModule.forRoot(),
    ReactiveFormsModule,
    NgbModule,
    JwPaginationModule,
    CarouselModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    MyLoaderComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    FindContestComponent,
    ThuVienTaiLieuComponent,
    HomeComponent,
    RegisterComponent,
    ContestOnlineViewComponent,
    ContestOnlineThiComponent,
    BlogComponent,
    FilterPipe,
    TinTucComponent,
    BlogComponent,
    VerifyEmailComponent,
    SafePipe,
    AccessRoomComponent,
    KhoaHocComponent,
    KhoaHocDetailComponent,
    KhoaHocMainComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent
  ],
  providers: [
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy
    // },
    LoaderService,
    IconSetService,
    authInterceptorProviders
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
