import { LectureQuizQuestion } from "./lecture-quiz-question"

export class LectureQuiz {
    id?: number
    name?: string
    timeMinute?: number
    displayNumberQuestion?: number
    displayAnswer?: string
    limitNumberAssign?: number
    mixQuestions?: string
    numberPassed?: number
    createdDate?: string
    userId?:string
    lectureQuizQuestion?: LectureQuizQuestion[] = []

    constructor(data: any) {
        if (data) {
            this.id = data.id;
            this.name = data.name;
            this.lectureQuizQuestion = [];
            this.timeMinute = data.timeMinute;
            this.displayNumberQuestion = data.displayNumberQuestion;
            this.displayAnswer = data.displayAnswer;
            this.limitNumberAssign = data.limitNumberAssign;
            this.mixQuestions = data.mixQuestions;
            this.numberPassed = data.numberPassed;
            data.lectureQuizQuestion.forEach(q => {
                this.lectureQuizQuestion.push(new LectureQuizQuestion(q));
            });
        }
    }
}
