import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../common/app.constants';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ProtectedService {

  constructor(private http: HttpClient) { }

  getStudentOfRoomByCurrentUserProtected(search, page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'studentOfRoom/byCurrentUser?search='+search+'&page='+page+'&size='+size,httpOptions);
  }

  getNewRoomByNameProtected(search, page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'room/searchList?search='+search+'&page='+page+'&size='+size,httpOptions);
  }
  
  getRoomByCode(roomCode): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'room/roomCode/' + roomCode,httpOptions);
  }
  getRoomIdRoleStudent(id): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'roomRoleStudent/' + id,httpOptions);
  }
}
