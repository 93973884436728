import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Contest } from '../../../models/contest';
import { Library } from '../../../models/thuvien/library';
import { Thuvien } from '../../../models/thuvien/thuvien';
import { AppConstants } from '../../common/app.constants';
import { AuthService } from '../../_services/auth.service';
import { ContestService } from '../../_services/contest.service';
import { ThuvienService } from '../../_services/thuvien.service';
import { TokenStorageService } from '../../_services/token-storage.service';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-thuvientailieu',
  templateUrl: 'thu-vien-tai-lieu.component.html',
  styleUrls: ['thu-vien-tai-lieu.css']

})
export class ThuVienTaiLieuComponent {
  libraries: Library = {
    libraryDocuments: []
  }
  avatar: string;
  form: any = {};
  isLoggedIn = false;
  roles: string[];
  showAdminBoard = false;
  showHuyenTPBoard = false;
  isHomeFailed = false;
  errorMessage = '';
  currentUser: any;
  username: string;
  googleURL = AppConstants.GOOGLE_AUTH_URL;
  usersArray = [];
  stringObject
  codeContest = '';
  wrong = false
  thuvienId: any
  backendOrigin = AppConstants.API_URL2

  constructor(private contestService: ContestService, private tokenStorage: TokenStorageService, private route: ActivatedRoute, private userService: UserService,
    private thuvienService: ThuvienService, private http: HttpClient, private router: Router) {
    this.route.queryParams.subscribe(params => {
      this.thuvienId = params['thuvien'];
    })
  }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorage.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorage.getUser();
      this.username = user.displayName;
      this.avatar = AppConstants.API_URL2 + user.avatar;
      this.roles = user.roles;
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showHuyenTPBoard = this.roles.includes('ROLE_HUYENTP');
    }
    if(this.thuvienId == undefined){
      this.router.navigateByUrl('home');
    } else {
      this.getLibraryById(this.thuvienId)
    }
  }

  findContest(){
    if(this.codeContest){
      this.contestService.getSlugByCode(this.codeContest).subscribe(
        data => {
          if(!data.error){
            this.router.navigateByUrl('/contest/' + data.resultData)
          } else {
            this.wrong = true;
          }
        }, err => {
        }
      )
    }
  }

  getLibraryById(thuvienId) {
    this.thuvienService.getLibraryId(thuvienId).pipe(map((data: any) => data.resultData)).subscribe(
      data => {
        this.libraries = data;
        this.libraries.libraryDocuments.map((thuvien) => {
          thuvien.libraryDocumentItems.map((thuVienItem) => {
            if(thuVienItem.filePath.endsWith('.xls') || thuVienItem.filePath.endsWith('.xlsx')) {
              thuVienItem.fileImageTemp = '/api/downloadThuvien/excel.png'
            } else if (thuVienItem.filePath.endsWith('.csv')) {
              thuVienItem.fileImageTemp = '/api/downloadThuvien/csv.png';
            } else if (thuVienItem.filePath.endsWith('.doc') || thuVienItem.filePath.endsWith('.docx')) {
              thuVienItem.fileImageTemp = '/api/downloadThuvien/word.png';
            } else if (thuVienItem.filePath.endsWith('.pdf')) {
              thuVienItem.fileImageTemp = '/api/downloadThuvien/pdf.png';
            } else if (thuVienItem.filePath.endsWith('.mp3') || thuVienItem.filePath.endsWith('.wav')) {
              thuVienItem.fileImageTemp = '/api/downloadThuvien/mp3.png';
            } else if (thuVienItem.filePath.endsWith('.m4v') || thuVienItem.filePath.endsWith('.mp4') || thuVienItem.filePath.endsWith('.mov')) {
              thuVienItem.fileImageTemp = '/api/downloadThuvien/video.png';
            } else if (thuVienItem.filePath.endsWith('.png') || thuVienItem.filePath.endsWith('.jpg') || thuVienItem.filePath.endsWith('.jpeg')) {
              thuVienItem.fileImageTemp = thuVienItem.filePath;
            } else {
              thuVienItem.fileImageTemp = '/api/downloadThuvien/other.png';
            }
          })
        })
      }, err => {
      }
    )
  }

  logout(): void {
    this.tokenStorage.signOut();
    window.location.reload();
  }

  subStringDate(text: string){
    return text.substr(20, text.length)
  }
}
