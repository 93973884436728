import { HttpClient, HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Contest } from '../../../models/contest';
import { AppConstants } from '../../common/app.constants';
import { AuthService } from '../../_services/auth.service';
import { ContestService } from '../../_services/contest.service';
import { TokenStorageService } from '../../_services/token-storage.service';
import { UserService } from '../../_services/user.service';
const search: Params = { myParam: 'search' }
@Component({
  selector: 'app-dashboard',
  templateUrl: 'findContest.component.html',
  styleUrls: ['findContest.css']

})
export class FindContestComponent {
  contests: Contest[];
  avatar: string;
  form: any = {};
  isLoggedIn = false;
  roles: string[];
  showAdminBoard = false;
  showHuyenTPBoard = false;
  isHomeFailed = false;
  errorMessage = '';
  currentUser: any;
  username: string;
  googleURL = AppConstants.GOOGLE_AUTH_URL;
  usersArray = [];
  stringObject
  searchText: any
  codeContest = '';
  wrong = false;
  backendOrginal = AppConstants.API_URL2

  maxSize: number = 5;
  bigTotalItems: number = 51;
  bigCurrentPage: number = 1;
  numPages: number = 0;
  

  constructor(private authService: AuthService, private tokenStorage: TokenStorageService, private route: ActivatedRoute, private userService: UserService,
    private contestService: ContestService, private http: HttpClient, private router: Router) {
      this.route.queryParams.subscribe(params => {
      let search = params['search'];
      if (search == undefined) {
        this.searchText = ''
      } else {
        this.searchText = search
      }
    });
  }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorage.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorage.getUser();
      this.username = user.displayName;
      this.avatar = AppConstants.API_URL2 + user.avatar;
      this.roles = user.roles;
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showHuyenTPBoard = this.roles.includes('ROLE_HUYENTP');
    }
    this.getAllContestBySearch(this.searchText, 0);
  }

  onSubmit(): void {
    if(this.searchText){
      this.getAllContestBySearch(this.searchText,0)
    } else {
      this.getAllContestBySearch('',0)
    }
    this.router.navigate([], {relativeTo: this.route, queryParams: {search: this.searchText }, queryParamsHandling: 'merge'})
  }

  findContest(){
    if(this.codeContest){
      this.contestService.getSlugByCode(this.codeContest).subscribe(
        data => {
          if(!data.error){
            this.router.navigateByUrl('/contest/' + data.resultData)
          } else {
            this.wrong = true;
          }
        }, err => {
        }
      )
    }
  }

  pageChanged(event: any): void {
    if(this.searchText){
      this.getAllContestBySearch(this.searchText,event.page-1)
    } else {
      this.getAllContestBySearch('',event.page-1)
    }
  }

  getAllContestBySearch(search, page) {
    this.contestService.getAllContestBySearch(search, page, 12).pipe(map((data: any) => data.resultData)).subscribe(
      data => {
        this.contests = data.content;
        this.contests.map((contest) => {
          contest.imageBanner = AppConstants.API_URL2 + contest.imageBanner;
          contest.slug = this.clean_url(contest.contestName) + '-' + contest.id;
        })
        this.bigTotalItems = data.totalElements
      }, err => {
      }
    )
  }

  clean_url(s) {
    return s.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, "") //remove diacritics
      .toLowerCase()
      .replace(/\s+/g, '-') //spaces to dashes
      .replace(/&/g, '-and-') //ampersand to and
      .replace(/[^\w\-]+/g, '') //remove non-words
      .replace(/\-\-+/g, '-') //collapse multiple dashes
      .replace(/^-+/, '') //trim starting dash
      .replace(/-+$/, ''); //trim ending dash
  }

  logout(): void {
    this.tokenStorage.signOut();
    window.location.reload();
  }
}
