import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Contest } from '../../../models/contest';
import { News } from '../../../models/News';
import { AppConstants } from '../../common/app.constants';
import { NewsService } from '../../_services/news.service';
import { TokenStorageService } from '../../_services/token-storage.service';
import * as ckCustomBuild from '../../ckCustomBuild/build/ckEditor';
import { ContestService } from '../../_services/contest.service';
@Component({
  templateUrl: 'tintuc.component.html',
  styleUrls: ['tintuc.css']
})
export class TinTucComponent {
  public Editor = ckCustomBuild;
  @Input() readonly: boolean = true;
  contests: Contest[];
  news: News = {};
  newsResultSearch: News[] = []
  avatar: string;
  form: any = {};
  isLoggedIn = false;
  roles: string[];
  showAdminBoard = false;
  showHuyenTPBoard = false;
  isHomeFailed = false;
  errorMessage = '';
  currentUser: any;
  username: string;
  googleURL = AppConstants.GOOGLE_AUTH_URL;
  usersArray = [];
  stringObject
  searchText: any
  slug: any
  codeContest = '';
  wrong = false;
  newsRelated: News[] = []

  maxSize: number = 5;
  bigTotalItems: number = 51;
  bigCurrentPage: number = 1;
  numPages: number = 0;
  // backendOriginal = AppConstants.API_URL2;


  public config = {
    toolbar: {
      items: []
    },
    
  }

  constructor(private tokenStorage: TokenStorageService, private route: ActivatedRoute,
    private newsService: NewsService, private router: Router, private contestService: ContestService) {
      this.route.params.subscribe(params => {
        if(params['slug']){
          this.slug = params['slug'];
        }
      });
  }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorage.getToken();
    this.getNewsBySlug(this.slug);
    if (this.isLoggedIn) {
      const user = this.tokenStorage.getUser();
      this.username = user.displayName;
      this.avatar = AppConstants.API_URL2 + user.avatar;
      this.roles = user.roles;
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showHuyenTPBoard = this.roles.includes('ROLE_HUYENTP');
    }
  }

  onSubmit(): void {
    if(this.searchText == null || this.searchText == undefined || this.searchText == '' || this.searchText == 'null') {
      this.newsResultSearch = []
    } else {
      this.searchNews()
    }
    
  }

  getNewsBySlug(slug){
    this.newsService.getNewsBySlug(slug).pipe(map((data: any) => data.resultData)).subscribe(
      data => {
        if(data === 'Does not exist'){
          this.router.navigate(['/']);
        } else {
          this.news = data;
          this.news.imageBanner = AppConstants.API_URL2 + this.news.imageBanner;
          this.getNewsRelated(this.news.id)
        }
      }, err => {

      }
    )
  }

  searchNews() {
    this.newsService.getNewsLatest(this.searchText, 0, 12).pipe(map((data: any) => data.resultData)).subscribe(
      response => {
        this.newsResultSearch = response.content;
        console.log(this.newsResultSearch)
        this.newsResultSearch.map((x) =>{
          x.imageBanner = AppConstants.API_URL2 + x.imageBanner;
          x.slug = this.clean_url(x.title) + '-' + x.id
        })
        this.bigTotalItems = response.totalElements
      }, err => {

      }
    )
  }
  
  getNewsRelated(newsId){
    this.newsService.getNewsRelated(newsId).pipe(map((data: any) => data.resultData)).subscribe(
      data => {
        this.newsRelated = data
        this.newsRelated.map((news) => {
          news.imageBanner = AppConstants.API_URL2 + news.imageBanner;
        })
      }, err => {

      }
    )
  }

  findContest() {
    if(this.codeContest){
      this.contestService.getSlugByCode(this.codeContest).subscribe(
        data => {
          if(!data.error){
            this.router.navigateByUrl('/contest/' + data.resultData)
          } else {
            this.wrong = true;
          }
        }, err => {

        }
      )
    }
  }

  clean_url(s) {
    return s.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, "") //remove diacritics
      .toLowerCase()
      .replace(/\s+/g, '-') //spaces to dashes
      .replace(/&/g, '-and-') //ampersand to and
      .replace(/[^\w\-]+/g, '') //remove non-words
      .replace(/\-\-+/g, '-') //collapse multiple dashes
      .replace(/^-+/, '') //trim starting dash
      .replace(/-+$/, ''); //trim ending dash
  }

  logout(): void {
    this.tokenStorage.signOut();
    window.location.reload();
  }
}
