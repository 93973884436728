import { QuizConfig } from './quiz-config';
import { Question } from './question';
import { ExamEssay } from '../examEssay';

export class Quiz {
    id: number;
    title: string;
    config: QuizConfig;
    examQuestion: Question[];
    examEssays?: ExamEssay[];
    constructor(data: any) {
        if (data) {
            this.id = data.id;
            this.title = data.title;
            this.config = new QuizConfig(data.config);
            this.examQuestion = [];
            data.examQuestion.forEach(q => {
                this.examQuestion.push(new Question(q));
            });
        }
    }
}