import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../../common/app.constants';

const httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};

@Injectable({
  providedIn: 'root'
})
export class LectureLectureDetailStudentQuizResultService {

  constructor(private http: HttpClient) { }

  addLectureLectureDetailStudentQuizResult(data): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'lectureLectureDetailStudentQuizResult/add', data, httpOptions);
  }
}
