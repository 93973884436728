import { LectureQuizAnswer } from "./lecture-quiz-answer";

export class LectureQuizQuestion {
    id?: number
    name?: string
    typeOfQuestion?:string
    questionNumber?:number
    lectureQuizId?: number
    lectureQuizAnswer?: LectureQuizAnswer[] = [];
    questionTypeId?: number;

    constructor(data: any) {
        data = data || {};
        this.id = data.id;
        this.name = data.name;
        this.questionTypeId = data.typeOfQuestion === 'SINGLE' ? 1: 0;
        this.lectureQuizAnswer = [];
        data.lectureQuizAnswer.forEach(o => {
            this.lectureQuizAnswer.push(new LectureQuizAnswer(o));
        });
    }
}
