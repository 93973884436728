import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../common/app.constants';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class ExamEssayAnswerService {

  constructor(private http: HttpClient) { }

  getExamByCurrentUser(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'examEssayAnswer/byCurrentUser', httpOptions);
  }

  getUserBoard(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'user', { responseType: 'text' });
  }

  getModeratorBoard(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'mod', { responseType: 'text' });
  }

  getAdminBoard(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'admin', { responseType: 'text' });
  }

  getCurrentUser(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'user/me', httpOptions);
  }

  newEssayAnswer(examEssayId, resultContestId): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'examEssayAnswer/newEssayAnswer?examEssayId=' + examEssayId + '&resultContestId=' + resultContestId, httpOptions);
  }

  getByUserIdAndResultContestId(userId, resultContestId): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'examEssayAnswer/getByUserIdAndResultContestId?userId=' + userId + '&resultContestId=' + resultContestId, httpOptions);
  }

}
