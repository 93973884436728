import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { AccessRoomComponent } from './views/accessRoom/access-room.component';
import { BlogComponent } from './views/blog/blog.component';
import { ChangePasswordComponent } from './views/changePassword/change-password.component';
import { ContestOnlineThiComponent } from './views/contestonline/contestonline-thi.component';
import { ContestOnlineViewComponent } from './views/contestonline/contestonline-view.component';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { FindContestComponent } from './views/findContest/findContest.component';
import { ForgotPasswordComponent } from './views/forgotPassword/forgot-password.component';
import { HomeComponent } from './views/home/home.component';
import { KhoaHocDetailComponent } from './views/khoa-hoc/khoa-hoc-detail/khoa-hoc-detail.component';
import { KhoaHocMainComponent } from './views/khoa-hoc/khoa-hoc-main/khoa-hoc-main';
import { KhoaHocComponent } from './views/khoa-hoc/khoa-hoc.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ThuVienTaiLieuComponent } from './views/thu-vien-tai-lieu/thu-vien-tai-lieu.component';
import { TinTucComponent } from './views/tintuc/tintuc.component';
import { VerifyEmailComponent } from './views/verify-email/verify-email.component';

export const routes: Routes = [
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent,
    data: {
      title: 'Quên Mật Khẩu'
    }
  },
  {
    path: 'changepassword/verify/:userId',
    component: ChangePasswordComponent,
    data: {
      title: 'Cập Nhật Mật Khẩu'
    },
  },
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'Trang chủ'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'cl/:roomCode',
    component: AccessRoomComponent,
    data: {
      title: 'Truy cập phòng thi'
    }
  },
  {
    path: 'contest',
    component: P404Component,
    data: {
      title: 'contest online'
    },
  },
  {
    path: 'tim-cuoc-thi',
    component: FindContestComponent,
    data: {
      title: 'Tìm Cuộc Thi'
    },
  },
  {
    path: 'thu-vien',
    component: ThuVienTaiLieuComponent,
    data: {
      title: 'Thư Viện Tài Liệu'
    },
  },
  {
    path: 'tin-tuc/:slug',
    component: TinTucComponent,
    data: {
      title: 'Tin tức'
    },
  },
  {
    path: 'email/verify/:userId',
    component: VerifyEmailComponent,
    data: {
      title: 'Xác minh email'
    },
  },
  {
    path: 'khoa-hoc',
    data: {
      title: 'Khóa học'
    },
    children: [
      {
        path: '',
        component: KhoaHocComponent,
      },
      {
        path: ':slug',
        component: KhoaHocDetailComponent,
      },
      {
        path: ':idLearn/lecture/:idLecture/:idLectureDetailId',
        component: KhoaHocMainComponent,
      }
    ]
  },
  {
    path: 'blog/:slug',
    component: BlogComponent,
    data: {
      title: 'Blog'
    }
  },
  {
    path: 'contest/:slug',
    component: ContestOnlineViewComponent,
    data: {
      title: 'contest online view'
    },
  },
  {
    path: 'contest/:slug/thi',
    component: ContestOnlineThiComponent,
    data: {
      title: 'contest online thi'
    },
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      },
      {
        path: 'tai-khoan',
        loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'huyentp/tin-tuc',
        loadChildren: () => import('./views/desktop-huyentp/news/news.module').then(m => m.NewsHuyenTPModule)
      },
      {
        path: 'huyentp/cuoc-thi',
        loadChildren: () => import('./views/desktop-huyentp/contest/contest.module').then(m => m.ContestModule)
      },
      {
        path: 'huyentp/tai-lieu',
        loadChildren: () => import('./views/desktop-huyentp/contest/document/document.module').then(m => m.DocumentModule)
      },
      {
        path: 'huyentp/de-thi',
        loadChildren: () => import('./views/desktop-huyentp/contest/exam/exam.module').then(m => m.ExamModule)
      },
      {
        path: 'huyentp/nhom-don-vi',
        loadChildren: () => import('./views/desktop-huyentp/contest/organization/organization.module').then(m => m.OrganizationModule)
      },
      {
        path: 'huyentp/thong-ke',
        loadChildren: () => import('./views/desktop-huyentp/reports/report.module').then(m => m.ReportModule)
      },
      {
        path: 'permission',
        loadChildren: () => import('./views/desktop-huyentp/permission/permission.module').then(m => m.PermissionModule)
      },
      {
        path: 'lich-su',
        loadChildren: () => import('./views/history/history.module').then(m => m.HistoryModule)
      },
      {
        path: 'tinh-doan/tin-tuc',
        loadChildren: () => import('./views/desktop-tinhdoan/news/news.module').then(m => m.NewsTinhdoanModule)
      },
      {
        path: 'tinh-doan/bai-hoc',
        loadChildren: () => import('./views/desktop-tinhdoan/document/document.module').then(m => m.DocumentModule)
      },
      {
        path: 'tinh-doan/bai-tap',
        loadChildren: () => import('./views/desktop-tinhdoan/exam/exam.module').then(m => m.ExamModule)
      },
      {
        path: 'tinh-doan/lop-hoc',
        loadChildren: () => import('./views/desktop-tinhdoan/room/room.module').then(m => m.RoomModule)
      },
      {
        path: 'tinh-doan/tron-de-thi',
        loadChildren: () => import('./views/desktop-tinhdoan/shuffle-exam/shuffle-exam.module').then(m => m.ShuffleExamModule)
      },
      {
        path: 'tinh-doan/trac-nghiem',
        loadChildren: () => import('./views/desktop-tinhdoan/learn/trac-nghiem/trac-nghiem.module').then(m => m.TracNghiemModule)
      },
      {
        path: 'tinh-doan/khoa-hoc',
        loadChildren: () => import('./views/desktop-tinhdoan/learn/khoa-hoc/learn.module').then(m => m.LearnModule)
      },
      {
        path: 'tinh-doan/cuoc-thi',
        loadChildren: () => import('./views/desktop-tinhdoan/contest/contest.module').then(m => m.ContestModule)
      },
      {
        path: 'tinh-doan/de-thi',
        loadChildren: () => import('./views/desktop-tinhdoan/contest/exam/exam.module').then(m => m.ExamModule)
      },
      {
        path: 'tinh-doan/thong-ke',
        loadChildren: () => import('./views/desktop-tinhdoan/reports/report.module').then(m => m.ReportModule)
      },
      {
        path: 'tinh-doan/nhom-don-vi',
        loadChildren: () => import('./views/desktop-tinhdoan/contest/organization/organization.module').then(m => m.OrganizationModule)
      },
      {
        path: 'tinh-doan/tai-lieu',
        loadChildren: () => import('./views/desktop-tinhdoan/contest/document/document.module').then(m => m.DocumentModule)
      },
      {
        path: 'hoc-vien/lop-hoc',
        loadChildren: () => import('./views/desktop-nguoidung/room/room.module').then(m => m.RoomModule)
      },
      {
        path: 'thu-vien-tai-lieu',
        loadChildren: () => import('./views/thuvien/thuvien/thuvien.module').then(m => m.ThuVienModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
