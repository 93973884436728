import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../common/app.constants';

const httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};


@Injectable({
  providedIn: 'root'
})
export class ExamService {

  constructor(private http: HttpClient) { }

  getExamByCurrentUser(search, page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'exam/byCurrentUser?search='+ search + '&page='+ page + '&size='+ size,httpOptions);
  }

  getExamsByCurrentUser(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'exam/byCurrentUser/listAll',httpOptions);
  }

  getExamsNameByCurrentUser(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'exam/byCurrentUser/listAllName',httpOptions);
  }

  getExamId(id): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'exam/' + id,httpOptions);
  }

  addExam(data): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'exam/add',data ,httpOptions);
  }

  updateExam(data): Observable<any> {
    return this.http.put(AppConstants.API_URL + 'exam/update',data ,httpOptions);
  }

  deleteExamById(id): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'exam/delete/'+id ,httpOptions);
  }
  
  getCurrentUser(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'user/me', httpOptions);
  }

}

@Injectable({
  providedIn: 'root'
})
export class ExamByRoomService {

  constructor(private http: HttpClient) { }

  getExamsByCurrentUser(roomId): Observable<any> {
    return this.http.get(AppConstants.API_URL + `exam/byCurrentUser/listAllIgnoreExistRoom/${roomId}`,httpOptions);
  }
}

