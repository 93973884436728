import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Contest } from '../../../models/contest';
import { AppConstants } from '../../common/app.constants';
import { TokenStorageService } from '../../_services/token-storage.service';
import * as ckCustomBuild from '../../ckCustomBuild/build/ckEditor';
import { ContestService } from '../../_services/contest.service';
import { LearnService } from '../../_services/learn/learn.service';
import { Learn } from '../../../models/learn/learn';

@Component({
  templateUrl: 'khoa-hoc.component.html',
  styleUrls: ['khoa-hoc.css']
})
export class KhoaHocComponent {
  learns: Learn[] = [];
  avatar: string;
  form: any = {};
  isLoggedIn = false;
  roles: string[];
  showAdminBoard = false;
  showHuyenTPBoard = false;
  isHomeFailed = false;
  errorMessage = '';
  currentUser: any;
  username: string;
  googleURL = AppConstants.GOOGLE_AUTH_URL;
  usersArray = [];
  stringObject
  searchText: string = ''
  slug: any
  codeContest = '';
  wrong = false;

  maxSize: number = 5;
  bigTotalItems: number = 51;
  bigCurrentPage: number = 1;
  numPages: number = 0;
  page: number = 0
  size: number = 20;

  starRating = 0; 

  public config = {
    toolbar: {
      items: []
    },
    
  }

  constructor(private tokenStorage: TokenStorageService, private route: ActivatedRoute,
    private learnService: LearnService, private router: Router, private contestService: ContestService) {
      
  }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorage.getToken();
    this.getLearn(this.searchText, this.page, this.size);
    if (this.isLoggedIn) {
      const user = this.tokenStorage.getUser();
      this.username = user.displayName;
      this.avatar = AppConstants.API_URL2 + user.avatar;
      this.roles = user.roles;
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showHuyenTPBoard = this.roles.includes('ROLE_HUYENTP');
    }
  }

  onSubmit(): void {
    if(this.searchText){
      this.getLearn(this.searchText,0,10)
    } else {
      this.getLearn('',0,10)
    }
  }

  pageChanged(event: any): void {
    if(this.searchText){
      this.getLearn(this.searchText,event.page-1,10)
    } else {
      this.getLearn('',event.page-1,10)
    }
  }

  getLearn(search, page, size){
    this.learnService.getLearnByHasActiveListAll(search, page, size).pipe(map((data: any) => data.resultData)).subscribe(
      data => {
        this.learns = data.content;
        this.learns.map((learn) =>{
          learn.imageLearn = AppConstants.API_URL2 + learn.imageLearn;
          let totalSum = 0;
          learn.learnRating.map((rating) => {
            totalSum += parseInt(rating.points);
          })
          learn.learnRatingNumber = totalSum/learn.learnRating.length;
          learn.slug = this.clean_url(learn.name) + '-' + learn.id;
        })
        this.bigTotalItems = data.totalElements
      },err => {
      }
    )
  }
  
  findContest() {
    if(this.codeContest){
      this.contestService.getSlugByCode(this.codeContest).subscribe(
        data => {
          if(!data.error){
            this.router.navigateByUrl('/contest/' + data.resultData)
          } else {
            this.wrong = true;
          }
        }, err => {

        }
      )
    }
  }

  clean_url(s) {
    return s.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, "") //remove diacritics
      .toLowerCase()
      .replace(/\s+/g, '-') //spaces to dashes
      .replace(/&/g, '-and-') //ampersand to and
      .replace(/[^\w\-]+/g, '') //remove non-words
      .replace(/\-\-+/g, '-') //collapse multiple dashes
      .replace(/^-+/, '') //trim starting dash
      .replace(/-+$/, ''); //trim ending dash
  }

  logout(): void {
    this.tokenStorage.signOut();
    window.location.reload();
  }
}
