import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppConstants } from '../../common/app.constants';
import { navItems } from '../../_nav';
import { ContestService } from '../../_services/contest.service';
import { TokenStorageService } from '../../_services/token-storage.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {

  public sidebarMinimized = false;
  public navItems = navItems;
  isLoggedIn = false;
  username: string;
  avatar: string;
  codeContest = '';
  roles: string[];
  showHuyenTPBoard = false;
  showAdminBoard = false;
  showNguoidungBoard = false;
  showTinhdoanBoard = false;
  navbarRoleHuyenTP = [
    {
      name: 'Tài khoản',
      url: 'tai-khoan',
      icon: 'bi bi-person-fill'
    },
    {
      name: 'Tin tức',
      url: 'huyentp/tin-tuc',
      icon: 'bi bi-newspaper'
    },
    {
      name: 'Cuộc thi',
      url: 'huyentp/cuoc-thi',
      icon: 'bi bi-mortarboard-fill',
      children: [
        {
          name: 'Cuộc thi',
          url: 'huyentp/cuoc-thi',
          icon: 'bi bi-mortarboard-fill'
        },
        {
          name: 'Tài liệu',
          url: 'huyentp/tai-lieu',
          icon: 'bi bi-file-earmark-fill'
        },
        {
          name: 'Đề thi',
          url: 'huyentp/de-thi',
          icon: 'bi bi-journal-text'
        },
        {
          name: 'Nhóm',
          url: 'huyentp/nhom-don-vi',
          icon: 'bi bi-people-fill'
        },
        {
          name: 'Thống Kê',
          url: 'huyentp/thong-ke',
          icon: 'bi bi-newspaper'
        }
      ]
    },
    // {
    //   name: 'Thư viện tài liệu',
    //   url: 'thu-vien-tai-lieu',
    //   icon: 'bi bi-file-earmark-zip-fill'
    // }
  ]
  navbarRoleTinhdoan = [
    {
      name: 'Tài khoản',
      url: 'tai-khoan',
      icon: 'bi bi-person-fill'
    },
    {
      name: 'Tin tức',
      url: 'tinh-doan/tin-tuc',
      icon: 'bi bi-newspaper'
    },
    {
      name: 'Cuộc thi',
      url: 'tinh-doan/cuoc-thi',
      icon: 'bi bi-mortarboard-fill',
      children: [
        {
          name: 'Cuộc thi',
          url: 'tinh-doan/cuoc-thi',
          icon: 'bi bi-mortarboard-fill'
        },
        {
          name: 'Tài liệu',
          url: 'tinh-doan/tai-lieu',
          icon: 'bi bi-file-earmark-fill'
        },
        {
          name: 'Đề thi',
          url: 'tinh-doan/de-thi',
          icon: 'bi bi-journal-text'
        },
        {
          name: 'Nhóm',
          url: 'tinh-doan/nhom-don-vi',
          icon: 'bi bi-people-fill'
        }
        ,
        {
          name: 'Thống Kê',
          url: 'tinh-doan/thong-ke',
          icon: 'bi bi-newspaper'
        }
      ]
    },
    {
      name: 'Khóa học',
      url: 'tinh-doan/lop-hoc',
      icon: 'bi bi-mortarboard-fill',
      children: [
        {
          name: 'Trắc nghiệm',
          url: 'tinh-doan/trac-nghiem',
          icon: 'bi bi-brush-fill'
        },
        {
          name: 'Khóa học',
          url: 'tinh-doan/khoa-hoc',
          icon: 'bi bi-mortarboard-fill'
        }
      ]
    },
    {
      name: 'Lớp học',
      url: 'tinh-doan/lop-hoc',
      icon: 'bi bi-easel2-fill',
      children: [
        {
          name: 'Lớp học',
          url: 'tinh-doan/lop-hoc',
          icon: 'bi bi-easel2-fill'
        },
        {
          name: 'Bài học',
          url: 'tinh-doan/bai-hoc',
          icon: 'bi bi-file-earmark-fill'
        },
        {
          name: 'Bài tập',
          url: 'tinh-doan/bai-tap',
          icon: 'bi bi-journal-text'
        },
        {
          name: 'Trộn đề',
          url: 'tinh-doan/tron-de-thi',
          icon: 'bi bi-shuffle'
        }
      ]
    },
    {
      name: 'Thư viện tài liệu',
      url: 'thu-vien-tai-lieu',
      icon: 'bi bi-file-earmark-zip-fill'
      
    }
  ]
  navbarRoleNguoidung = [{
    name: 'Tài khoản',
    url: 'tai-khoan',
    icon: 'bi bi-person-fill'
  },
  {
    name: 'Lớp học',
    url: 'hoc-vien/lop-hoc',
    icon: 'bi bi-easel2-fill'
  },
  {
    name: 'Lịch sử',
    url: 'lich-su',
    icon: 'bi bi-clock-history'
  }]

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  constructor(private tokenStorageService: TokenStorageService, private router: Router,
    private contestService: ContestService) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.username = user.displayName;
      this.avatar = AppConstants.API_URL2 + user.avatar;
      this.roles = user.roles;
      this.showNguoidungBoard = this.roles.includes('ROLE_NGUOIDUNG');
      this.showTinhdoanBoard = this.roles.includes('ROLE_TINHDOAN');
      this.showHuyenTPBoard = this.roles.includes('ROLE_HUYENTP');
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
    }
    let desktopRole = localStorage.getItem('desktopRole')
    if (desktopRole === 'tinhdoan') {
      this.navItems = this.navbarRoleTinhdoan
    } else if (desktopRole === 'huyentp') {
      this.navItems = this.navbarRoleHuyenTP
    } else {
      this.navItems = this.navbarRoleNguoidung
    }
  }

  onSubmit() {
    if (this.codeContest) {
      this.contestService.getSlugByCode(this.codeContest).subscribe(
        data => {
          if (!data.error) {
            this.router.navigateByUrl('/contest/' + data.resultData)
          } else {
          }
        }, err => {
        }
      )
    }
  }

  changeRolesTinhdoan() {
    localStorage.setItem('desktopRole', 'tinhdoan')
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    let currentUrl = this.router.url + '?';

    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate(['/tai-khoan']);
      });
  }

  changeRolesNguoidung() {
    localStorage.setItem('desktopRole', 'nguoidung')
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    let currentUrl = this.router.url + '?';

    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate(['/tai-khoan']);
      });
  }

  changeRolesHuyenTP() {
    localStorage.setItem('desktopRole', 'huyentp')
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    let currentUrl = this.router.url + '?';

    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate(['/tai-khoan']);
      });
  }

  logout(): void {
    this.tokenStorageService.signOut();
    this.router.navigate(['/'])
  }
}
