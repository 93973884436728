import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../../common/app.constants';

const httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};

@Injectable({
  providedIn: 'root'
})
export class LearnStudentService {

  constructor(private http: HttpClient) { }
  
  addLearnStudent(learnId): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'learnStudent/add/' + learnId,httpOptions);
  }

  getNumberStudentOfLearn(learnId): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'learnStudent/studentOfLearn/' + learnId, httpOptions);
  }

  getPassedLearnStudent(learnId): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'learnStudent/checkStatus/' + learnId, httpOptions);
  }

  sendLearnRating(data): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'learnStudent/sendRating/', data,httpOptions);
  }
  
}
