import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Contest } from '../../../models/contest';
import { AppConstants } from '../../common/app.constants';
import { AuthService } from '../../_services/auth.service';
import { ContestService } from '../../_services/contest.service';
import { NewsService } from '../../_services/news.service';
import { TokenStorageService } from '../../_services/token-storage.service';
import { UserService } from '../../_services/user.service';
import * as ckCustomBuild from '../../ckCustomBuild/build/ckEditor';
import { BLogService } from '../../_services/blog.service';
import { Blog } from '../../../models/blog';
@Component({
  selector: 'app-blog',
  templateUrl: 'blog.component.html',
  styleUrls: ['blog.css']
})
export class BlogComponent {
  public Editor = ckCustomBuild;
  @Input() readonly: boolean = true;
  contests: Contest[];
  blog: Blog = {};
  avatar: string;
  form: any = {};
  isLoggedIn = false;
  roles: string[];
  showAdminBoard = false;
  showHuyenTPBoard = false;
  isHomeFailed = false;
  errorMessage = '';
  currentUser: any;
  username: string;
  googleURL = AppConstants.GOOGLE_AUTH_URL;
  usersArray = [];
  stringObject
  searchText: any
  slug: any
  codeContest = '';
  wrong = false

  maxSize: number = 5;
  bigTotalItems: number = 51;
  bigCurrentPage: number = 1;
  numPages: number = 0;

  public config = {
    toolbar: {
      items: []
    },
  }

  constructor(private tokenStorage: TokenStorageService, private route: ActivatedRoute,
    private bLogService: BLogService, private router: Router, private contestService: ContestService) {
      this.route.params.subscribe(params => {
        if(params['slug']){
          this.slug = params['slug'];
        }
      });
  }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorage.getToken();
    this.getBlogBySlug(this.slug);
    if (this.isLoggedIn) {
      const user = this.tokenStorage.getUser();
      this.username = user.displayName;
      this.avatar = AppConstants.API_URL2 + user.avatar;
      this.roles = user.roles;
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showHuyenTPBoard = this.roles.includes('ROLE_HUYENTP');
    }
  }

  onSubmit(): void {
    
  }

  getBlogBySlug(slug){
    this.bLogService.getBlogBySlug(slug).pipe(map((data: any) => data.resultData)).subscribe(
      data => {
        if(data === 'Does not exist'){
          this.router.navigate(['/']);
        } else {
          this.blog = data;
          this.blog.imageBanner = AppConstants.API_URL2 + this.blog.imageBanner;
        }
      }, err => {

      }
    )
  }

  findContest() {
    if(this.codeContest){
      this.contestService.getSlugByCode(this.codeContest).subscribe(
        data => {
          if(!data.error){
            this.router.navigateByUrl('/contest/' + data.resultData)
          } else {
            this.wrong = true;
          }
        }, err => {
        }
      )
    }
  }

  clean_url(s) {
    return s.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, "") //remove diacritics
      .toLowerCase()
      .replace(/\s+/g, '-') //spaces to dashes
      .replace(/&/g, '-and-') //ampersand to and
      .replace(/[^\w\-]+/g, '') //remove non-words
      .replace(/\-\-+/g, '-') //collapse multiple dashes
      .replace(/^-+/, '') //trim starting dash
      .replace(/-+$/, ''); //trim ending dash
  }

  logout(): void {
    this.tokenStorage.signOut();
    window.location.reload();
  }
}
