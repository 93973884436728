import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProtectedService } from '../../_services/protected.service';
import { TokenStorageService } from '../../_services/token-storage.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'access-room.component.html'
})
export class AccessRoomComponent {

  roomCode: any
  isLoggedIn = false;
  typeAccessRoom: string = ''
  errorMessage: string = '';

  constructor(private tokenStorage: TokenStorageService,private route: ActivatedRoute,
    private protectedService: ProtectedService, private router: Router,private toastr: ToastrService) {
    this.isLoggedIn = !!this.tokenStorage.getToken();
    if(this.isLoggedIn){
      this.route.params.subscribe(params => {
        if(params['roomCode']){
          this.roomCode = params['roomCode'];
          this.checkRoomCode(this.roomCode);
        }
      });
    }
  }

  checkRoomCode(roomCode){
    this.protectedService.getRoomByCode(roomCode).subscribe(
      response => {
        if(response.error){
          this.errorMessage = response.errorMessage;
        } else {
          if(response.resultData){
            this.typeAccessRoom = 'linkqr'
          } else {
            this.typeAccessRoom = 'approve'
          }
        }
      }, err => {
        this.toastr.error('Có lỗi trong quá trình tải dữ liệu')
      }
    )
  }

}
