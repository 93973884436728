import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConstants } from '../common/app.constants';

const httpOptions = {
		  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};
const httpOptionsExcel = {
	headers: new HttpHeaders(
  { 
    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    // 'Content-Disposition': 'attachment; filename=user.xlsx'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ContestService {

  constructor(private http: HttpClient) { }

  getContestByCurrentUser(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'contest/byCurrentUser',httpOptions);
  }

  getContestByCurrentUserListAll(search, page, size): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'contest/byCurrentUser/listAll?search='+search+'&page='+page+'&size='+size,httpOptions);
  }
  
  getAllContestBySearch(search: any, page: any, size: any): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'contest/list?search='+ search +'&page=' + page + '&size=' + size,httpOptions);
  }

  getContestId(id): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'contest/' + id,httpOptions);
  }

  getContestBySlug(slug): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'contest/slug/' + slug,httpOptions);
  }

  getContestBySlugProtectPassword(slug): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'contest/slug/' + slug + '/thi',httpOptions);
  }

  getSlugByCode(code): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'contest/code/' + code,httpOptions);
  }

  getExcelResultContest(fromDate, toDate, contestId): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'contest/export?fromDate='+ fromDate + '&toDate=' + toDate +'&contestId='+contestId,
      {headers: httpOptionsExcel.headers, responseType: 'blob'});
  }

  checkPassword(password,slug): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'contest/checkPassowrd/' + slug, password ,httpOptions);
  }

  addContest(data): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'contest/add',data ,httpOptions);
  }

  deleteContestById(id): Observable<any> {
    return this.http.post(AppConstants.API_URL + 'contest/deldete/'+id ,httpOptions);
  }

  updateContest(data): Observable<any> {
    return this.http.put(AppConstants.API_URL + 'contest/update',data ,httpOptions);
  }
  
  getCurrentUser(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'user/me', httpOptions);
  }

  getSummary(): Observable<any> {
    return this.http.get(AppConstants.API_URL + 'contest/summary', httpOptions);
  }
}
