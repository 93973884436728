import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { Contest } from '../../../models/contest';
import { Learn } from '../../../models/learn/learn';
import { News } from '../../../models/News';
import { Library } from '../../../models/thuvien/library';
import { Thuvien } from '../../../models/thuvien/thuvien';
import { AppConstants } from '../../common/app.constants';
import { AuthService } from '../../_services/auth.service';
import { ContestService } from '../../_services/contest.service';
import { LearnService } from '../../_services/learn/learn.service';
import { NewsService } from '../../_services/news.service';
import { ThuvienService } from '../../_services/thuvien.service';
import { TokenStorageService } from '../../_services/token-storage.service';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.css']
})
export class HomeComponent { 

  contests: Contest[];
  news: News[];
  avatar: string;
  form: any = {};
  isLoggedIn = false;
  isHomeFailed = false;
  errorMessage = '';
  currentUser: any;
  username: string;
  googleURL = AppConstants.GOOGLE_AUTH_URL;
  codeContest = '';
  wrong = false
  roles: string[];
  showAdminBoard = false;
  showHuyenTPBoard = false;
  backendOrigin = AppConstants.API_URL2;
  libraries: Library[] = [];
  learns: Learn[] = []
  countContest: number;
  countUser: number;
  countTime: number;
  searchText: any

  constructor(private authService: AuthService, private tokenStorage: TokenStorageService, private route: ActivatedRoute, private userService: UserService,
    private contestService: ContestService, private router: Router, private newsService: NewsService,
    private thuvienService: ThuvienService, private toastr: ToastrService,
    private learnService: LearnService) {}

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorage.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorage.getUser();
      this.username = user.displayName;
      this.avatar = AppConstants.API_URL2 + user.avatar;
      this.roles = user.roles;
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showHuyenTPBoard = this.roles.includes('ROLE_HUYENTP');
    }
    this.getAllContest()
    this.getNewsLatest('',0,4);
    this.getAllLibrary();
    this.getLearn('',0,3);
    this.getSummary()
  }

  getAllContest(){
    this.contestService.getAllContestBySearch('',0,5).pipe(map((data: any) => data.resultData.content)).subscribe(
      data => {
        this.contests = data;
        this.contests.map((contest) =>{
          contest.imageBanner = AppConstants.API_URL2 + contest.imageBanner;
          contest.slug = this.clean_url(contest.contestName) + '-' + contest.id;
        })
      },err => {
      }
    )
  }

  onSubmit() {
    this.router.navigateByUrl('tim-cuoc-thi?search=' + this.searchText)
  }

  getNewsLatest(search, page, size){
    this.newsService.getNewsLatest(search,page, size).pipe(map((data: any) => data.resultData)).subscribe(
      data => {
        this.news = data.content
        this.news.map((x) =>{
          x.imageBanner = AppConstants.API_URL2 + x.imageBanner;
          x.slug = this.clean_url(x.title) + '-' + x.id
        })
      }, err => {

      }
    )
  }

  getSummary(){
    this.contestService.getSummary().pipe(map((data: any) => data.resultData)).subscribe(
      response => {
        this.countContest = response.countContest
        this.countUser = response.countUser
        this.countTime = Math.floor(response.countTime / 60)
      }, err => {

      }
    )
  }

  findContest() {
    if(this.codeContest){
      this.contestService.getSlugByCode(this.codeContest).subscribe(
        data => {
          if(!data.error){
            this.router.navigateByUrl('/contest/' + data.resultData)
          } else {
            this.wrong = true
          }
        }, err => {
        }
      )
    }
  }

  clean_url(s) {
    return s.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, "") //remove diacritics
      .toLowerCase()
      .replace(/\s+/g, '-') //spaces to dashes
      .replace(/&/g, '-and-') //ampersand to and
      .replace(/[^\w\-]+/g, '') //remove non-words
      .replace(/\-\-+/g, '-') //collapse multiple dashes
      .replace(/^-+/, '') //trim starting dash
      .replace(/-+$/, ''); //trim ending dash
  }

  logout(): void {
    this.tokenStorage.signOut();
    window.location.reload();
  }

  getAllLibrary(){
    this.thuvienService.getAllLibrary().pipe(map((data: any) => data.resultData)).subscribe(
      data => {
        this.libraries = data;
      }, err => {
        this.toastr.error('Có lỗi trong quá trình tải thư viện');
      }
    )
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth', block: 'start',inline: 'start'});
  }

  getLearn(search, page, size){
    this.learnService.getLearnByHasActiveListAll(search, page, size).pipe(map((data: any) => data.resultData)).subscribe(
      data => {
        this.learns = data.content;
        this.learns.map((learn) =>{
          learn.imageLearn = AppConstants.API_URL2 + learn.imageLearn;
          learn.slug = this.clean_url(learn.name) + '-' + learn.id;
        })
      },err => {
      }
    )
  }

  myInterval: number | false = 4000;
  ngOnDestroy(): void {
    this.myInterval = 0;
  }
}
