import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppConstants } from '../../common/app.constants';
import { TokenStorageService } from '../../_services/token-storage.service';
import { UserService } from '../../_services/user.service';
@Component({
  templateUrl: 'verify-email.component.html',
  styleUrls: ['verify-email.scss']
})
export class VerifyEmailComponent {
  isLoggedIn = false;
  userId
  expries
  signature
  message = ''
  backgroundColorSucess = '#F8FAF5'
  backgroundColorError = '#faf5f5'
  h4Success = '#88B04B'
  h4Error = '#b04b4b'
  statusValid: boolean = true;

  constructor(private tokenStorage: TokenStorageService, private route: ActivatedRoute, private router: Router,
    private userService: UserService) {
      this.route.params.subscribe(params => {
        if(params['userId'] && !isNaN(Number(params['userId']))){
          this.userId = Number(params['userId']);
        } else {
          this.statusValid = false;
          this.message = 'Link xác minh không đúng định dạng';
          return;
        }
      });
      this.route.queryParams.subscribe(params => {
        if(params['expries'] && !isNaN(Number(params['expries'])) && params['signature']){
          this.expries = Number(params['expries']);
          this.signature = params['signature'];
        } else {
          this.statusValid = false;
          this.message = 'Link xác minh không đúng định dạng';
          return;
        }
      })
  }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorage.getToken();
    if (!this.isLoggedIn) {
      let stateUrl = window.location.href.substring(window.location.origin.length, window.location.href.length);
      this.router.navigate(['/login'], { queryParams: { returnUrl: stateUrl }});
    } else {
      if(this.statusValid){
        this.checkVerifyEmail(this.userId, this.expries, this.signature);
      }
    }
  }

  checkVerifyEmail(userId, expries, signature) {
    const data = {
      userId: userId,
      expries: expries,
      signature: signature
    }
    this.userService.checkVerifyEmail(data).subscribe(
      response => {
        if(response.error){
          this.statusValid = false;
          this.message = response.errorMessage;
          return;
        } else {
          this.statusValid = true;
          this.message = response.resultData;
          return;
        }
      }, err => {}
    )
  }

}
