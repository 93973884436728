import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../../common/app.constants';
import { AuthService } from '../../_services/auth.service';
import { TokenStorageService } from '../../_services/token-storage.service';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent { 

  showPassword = false;
  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  currentUser: any;
  googleURL = AppConstants.GOOGLE_AUTH_URL;
  roles: string[];
  showHuyenTPBoard = false;
  showAdminBoard = false;
  showNguoidungBoard = false;
  showTinhdoanBoard = false;

  constructor(private authService: AuthService, private tokenStorage: TokenStorageService, private route: ActivatedRoute, private userService: UserService,
    private router: Router) {}

  ngOnInit(): void {
    const token: string = this.route.snapshot.queryParamMap.get('token');
	  const error: string = this.route.snapshot.queryParamMap.get('error');
    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'
  	if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.currentUser = this.tokenStorage.getUser();
      this.roles = this.currentUser.roles;
      this.showNguoidungBoard = this.roles.includes('ROLE_NGUOIDUNG');
      this.showTinhdoanBoard = this.roles.includes('ROLE_TINHDOAN');
      this.showHuyenTPBoard = this.roles.includes('ROLE_HUYENTP');
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      let desktopRole = localStorage.getItem('desktopRole')
      if (this.showTinhdoanBoard) {
        localStorage.setItem('desktopRole', 'tinhdoan')
      } else if (this.showHuyenTPBoard) {
        localStorage.setItem('desktopRole', 'huyentp')
      } else {
        localStorage.setItem('desktopRole', 'nguoidung')
      }

      this.router.navigateByUrl(returnUrl)
    }
  	else if(token){
  		this.tokenStorage.saveToken(token);
  		this.userService.getCurrentUser().subscribe(
        data => {
          this.login(data);
        },
        err => {
          this.errorMessage = err.error.message;
          this.isLoginFailed = true;
        }
      );
      this.router.navigateByUrl(returnUrl)
  	}
  	else if(error){
  		this.errorMessage = error;
	    this.isLoginFailed = true;
  	}
  }

  onSubmit(): void {
    this.authService.login(this.form).subscribe(
      data => {
        this.tokenStorage.saveToken(data.accessToken);
        this.login(data.user);
      },
      err => {
        this.errorMessage = 'Sai email hoặc mật khẩu!';
        this.isLoginFailed = true;
      }
    );
  }

  changed(evt) {
    this.showPassword = evt.target.checked
  }

  login(user): void {
    this.tokenStorage.saveUser(user);
    this.isLoginFailed = false;
    this.isLoggedIn = true;
    this.currentUser = this.tokenStorage.getUser();
    window.location.reload();
  }


}
