import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Learn } from '../../../../models/learn/learn';
import { LectureDetail } from '../../../../models/learn/lecture-detail';
import { AppConstants } from '../../../common/app.constants';
import { LearnService } from '../../../_services/learn/learn.service';
import { TokenStorageService } from '../../../_services/token-storage.service';
import HLS from 'hls.js';
import { QuizConfig } from '../../../../models/quiz/quiz-config';
import { LectureQuiz } from '../../../../models/learn/lecture-quiz';
import { LectureQuizQuestion } from '../../../../models/learn/lecture-quiz-question';
import { LectureQuizAnswer } from '../../../../models/learn/lecture-quiz-answer';
import { LectureLectureDetailStudentService } from '../../../_services/learn/lecture-lecturedetail-student.service';
import { LectureLectureDetailStudent } from '../../../../models/learn/lecture-lecturedetail-student';
import { ToastrService } from 'ngx-toastr';
import { LectureDetailCommentService } from '../../../_services/learn/lecture-detail-comment.service';
import { LectureDetailComment } from '../../../../models/learn/lecture-detail-comment';
import { LectureLectureDetailStudentQuizResultService } from '../../../_services/learn/lecture-lecturedetail-student-quiz-result.service';
import { LectureLectureDetailStudentQuizResult } from '../../../../models/learn/lecture-lecturedetail-student-quiz-result';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    templateUrl: 'khoa-hoc-main.html',
    styleUrls: ['khoa-hoc-main.css']
})
export class KhoaHocMainComponent {

    @ViewChild('autoShownModal', { static: false }) autoShownModal?: ModalDirective;
    isModalShown = false;
    @ViewChild('video', { static: true }) private readonly video: ElementRef<HTMLVideoElement>;
    backendOrgin = AppConstants.API_URL2
    avatar: string;
    isLoggedIn = false;
    roles: string[];
    showAdminBoard = false;
    showHuyenTPBoard = false;
    username: string;
    userIdLoggin: any
    idLearn: number;
    idLecture: number;
    idLectureDetailId: number;
    learn: Learn = {};
    lectureDetail: LectureDetail = {}
    lectureLectureDetailStudent: LectureLectureDetailStudent = {
        lectureLectureDetailStudentQuizResult: []
    }
    listLDPassedId: number[] = []
    public hideContent: boolean[] = [];
    public hideFormComment: boolean[] = [];
    public loading: boolean;
    public ignore: boolean;
    public playing = false;
    private hls = new HLS();
    config: QuizConfig = {
        'allowBack': true,
        'allowReview': true,
        'autoMove': false,  // if true, it will move to next question automatically when answered.
        'duration': 300,  // indicates the time (in secs) in which quiz needs to be completed. 0 means unlimited.
        'pageSize': 1,
        'requiredAll': false,  // indicates if you must answer all the questions before submitting.
        'richText': false,
        'shuffleQuestions': true,
        'shuffleOptions': true,
        'showClock': true,
        'showPager': true,
        'theme': 'none'
    };
    lectureQuiz: LectureQuiz = new LectureQuiz(null);
    mode = 'summary';
    timer: any = null;
    startTime: Date;
    endTime: Date;
    ellapsedTime = '00:00';
    duration = '';
    commentField = '';
    createdDate: Date;

    arrayResult: any = [];
    lectureLectureDetailStudentQuizResultModal: LectureLectureDetailStudentQuizResult = {}

    constructor(private tokenStorage: TokenStorageService, private route: ActivatedRoute,
        private learnService: LearnService, private router: Router, private toastr: ToastrService, 
        private lectureLectureDetailStudentService: LectureLectureDetailStudentService,
        private lectureDetailCommentService: LectureDetailCommentService, 
        private lLDSQResultService: LectureLectureDetailStudentQuizResultService) {
        this.route.params.subscribe(params => {
            if (params['idLearn'] && params['idLecture'] && params['idLectureDetailId']) {
                this.idLearn = parseInt(params['idLearn']);
                this.idLecture = parseInt(params['idLecture']);
                this.idLectureDetailId = parseInt(params['idLectureDetailId']);
            }
        });
    }

    ngOnInit(): void {
        this.isLoggedIn = !!this.tokenStorage.getToken();
        if (this.isLoggedIn) {
            const user = this.tokenStorage.getUser();
            this.username = user.displayName;
            this.avatar = AppConstants.API_URL2 + user.avatar;
            this.userIdLoggin = user.id
            this.roles = user.roles;
            this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
            this.showHuyenTPBoard = this.roles.includes('ROLE_HUYENTP');
            this.getLearnById(this.idLearn);
            this.getLectureLectureDetailStudent()
        } else {
            let stateUrl = window.location.href.substring(window.location.origin.length, window.location.href.length);
            this.router.navigate(['/login'], { queryParams: { returnUrl: stateUrl }});
        }
    }

    getLectureLectureDetailStudent(){
        const data = {
            learnId: this.idLearn,
            lectureId: this.idLecture,
            lectureDetailId: this.idLectureDetailId
        }
        this.lectureLectureDetailStudentService.getLectureLectureDetailStudent(data).subscribe(
            response => {
                if(response.error){
                    this.toastr.error(response.errorMessage, 'Thất bại')
                } else {
                    this.lectureLectureDetailStudent = response.resultData;
                    if(this.lectureLectureDetailStudent.lectureLectureDetailStudentQuizResult === null){
                        this.lectureLectureDetailStudent.lectureLectureDetailStudentQuizResult = []
                    }
                }
            }, err => {
                this.toastr.error('Có lỗi trong quá trình xử lý!', 'Thất bại')
            }
        )
    }

    endedVideo(){
        let id = this.lectureLectureDetailStudent.id;
        let passed = this.lectureLectureDetailStudent.passed;
        if(!passed){
            this.lectureLectureDetailStudentService.updatePassedLectureLectureDetailStudent(id).subscribe(
                response => {
                    if(response.error){
                        this.toastr.error(response.errorMessage, 'Thất bại')
                    } else {
                        this.lectureLectureDetailStudent = response.resultData
                    }
                }, err => {
                    this.toastr.error('Có lỗi trong quá trình xử lý!', 'Thất bại')
                }
            )
        }
    }

    getLearnById(idLearn) {
        this.learnService.getLearnId(idLearn).pipe(map((data: any) => data.resultData)).subscribe(
            data => {
                if (data === 'Does not exist') {
                    this.router.navigate(['/khoa-hoc']);
                } else {
                    this.learn = data;
                    if (this.learn.lecture.length == 0) {
                        this.router.navigate(['/khoa-hoc']);
                    }
                    this.learn.lecture.map(lecture => {
                        lecture.lectureDetail.map(lectureDetail => {
                            if (this.idLecture == lecture.id && this.idLectureDetailId == lectureDetail.id) {
                                this.lectureDetail = lectureDetail;
                                console.log(lectureDetail)
                                this.lectureDetail.lectureDetailDocument.map((lDD) => {
                                    if (lDD.filePath.endsWith('.xls') || lDD.filePath.endsWith('.xlsx')) {
                                        lDD.fileImageTemp = '/api/downloadThuvien/excel.png'
                                    } else if (lDD.filePath.endsWith('.csv')) {
                                        lDD.fileImageTemp = '/api/downloadThuvien/csv.png';
                                    } else if (lDD.filePath.endsWith('.doc') || lDD.filePath.endsWith('.docx')) {
                                        lDD.fileImageTemp = '/api/downloadThuvien/word.png';
                                    } else if (lDD.filePath.endsWith('.pdf')) {
                                        lDD.fileImageTemp = '/api/downloadThuvien/pdf.png';
                                    } else if (lDD.filePath.endsWith('.mp3') || lDD.filePath.endsWith('.wav')) {
                                        lDD.fileImageTemp = '/api/downloadThuvien/mp3.png';
                                    } else if (lDD.filePath.endsWith('.m4v') || lDD.filePath.endsWith('.mp4') || lDD.filePath.endsWith('.mov')) {
                                        lDD.fileImageTemp = '/api/downloadThuvien/video.png';
                                    } else if (lDD.filePath.endsWith('.png') || lDD.filePath.endsWith('.jpg') || lDD.filePath.endsWith('.jpeg')) {
                                        lDD.fileImageTemp = lDD.filePath;
                                    } else {
                                        lDD.fileImageTemp = '/api/downloadThuvien/other.png';
                                    }
                                })
                            }
                        })
                    })
                    if (this.lectureDetail.lectureType === 'VIDEO') {
                        this.load(
                            this.backendOrgin + this.lectureDetail.lectureVideoM3u8,
                            this.backendOrgin + this.lectureDetail.lectureVideoPoster
                        );
                    }
                    if (this.lectureDetail.lectureType === 'QUIZ') {
                        this.lectureQuiz = new LectureQuiz(this.lectureDetail.lectureQuiz)
                    }
                }
                this.getLectureDetailPassed(this.learn.id)
            }, err => {

            }
        )
    }

    loadLectureDetail(lectureId, lectureDetailId) {
        this.router.navigateByUrl('/khoa-hoc/' + this.idLearn + '/lecture/' + lectureId + '/' + lectureDetailId).then(() => {
            window.location.reload();
        });
    }

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    toggle(index) {
        this.hideContent[index] = !this.hideContent[index];
    }

    convertToSeconds(hms) {
        const [hours, minutes, seconds] = hms.split(':');
        return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
    }

    public load(currentVideo: string, poster: string): void {
        if (HLS.isSupported()) {
            this.loadVideoWithHLS(currentVideo, poster);
        } else {
            if (this.video.nativeElement.canPlayType('application/vnd.apple.mpegurl')) {
                this.loadVideo(currentVideo, poster);
            }
        }
    }

    private loadVideoWithHLS(currentVideo: string, poster: string) {
        this.hls.loadSource(currentVideo);
        this.video.nativeElement.poster = poster;
        this.hls.attachMedia(this.video.nativeElement);
        // this.hls.on(HLS.Events.MANIFEST_PARSED, () => this.video.nativeElement.play());
    }

    private loadVideo(currentVideo: string, poster: string) {
        this.video.nativeElement.src = currentVideo;
        this.video.nativeElement.poster = poster;
    }

    parseTime(totalSeconds: number) {
        let mins: string | number = Math.floor(totalSeconds / 60);
        let secs: string | number = Math.round(totalSeconds % 60);
        mins = (mins < 10 ? '0' : '') + mins;
        secs = (secs < 10 ? '0' : '') + secs;
        return mins + ':' + secs;
    }

    onSelect(lectureQuizQuestion: LectureQuizQuestion, lectureAnswer: LectureQuizAnswer) {
        if (lectureQuizQuestion.questionTypeId === 1) {
            lectureQuizQuestion.lectureQuizAnswer.forEach((x) => { if (x.id !== lectureAnswer.id) x.selected = false; });
        }
    }

    isAnswered(lectureQuizQuestion: LectureQuizQuestion) {
        return lectureQuizQuestion.lectureQuizAnswer.find(x => x.selected) ? 'Đã trả lời' : 'Chưa trả lời';
    };

    isCorrect(lectureQuizQuestion: LectureQuizQuestion) {
        lectureQuizQuestion.lectureQuizAnswer.forEach(x => {
            if (x.selected === undefined) {
                x.selected = false;
            }
        })
        return lectureQuizQuestion.lectureQuizAnswer.every(x => x.selected === x.isAnswer) ? 'đúng' : 'sai';
    };

    tick() {
        const now = new Date();
        const timeExam = this.lectureQuiz.timeMinute;
        if(timeExam!= 0){
            const diff = (now.getTime() - this.startTime.getTime()) / 1000;
            if (diff >= this.config.duration) {
                clearInterval(this.timer);
                this.onSubmitQuiz();
            }
            this.ellapsedTime = this.parseTime(diff);
        }
    }

    startQuiz(){
        this.createdDate = new Date();
        this.mode = 'quiz'
        this.lectureQuiz = new LectureQuiz(this.lectureDetail.lectureQuiz)
        const timeExam = this.lectureQuiz.timeMinute;
        if (timeExam != 0 && this.mode == 'quiz') {
            this.config.duration = timeExam * 60
            this.startTime = new Date();
            this.ellapsedTime = '00:00';
            this.timer = setInterval(() => { this.tick(); }, 1000);
            this.duration = this.parseTime(this.config.duration);
        }
    }

    onSubmitQuiz(){
        const data = {
            dateSubmit: new Date(),
            createdDate: this.createdDate,
            lectureQuiz: this.lectureQuiz,
            lectureLectureDetailStudentId: this.lectureLectureDetailStudent.id,
            learnId: this.learn.id
        }
        this.lLDSQResultService.addLectureLectureDetailStudentQuizResult(data).subscribe(
            response => {
                this.getLectureLectureDetailStudent();
            }, err => {

            }
        )
        this.tick();
        this.mode = 'summary'
    }

    viewResult(lectureLectureDetailStudentQuizResult: LectureLectureDetailStudentQuizResult){
        this.arrayResult = JSON.parse(lectureLectureDetailStudentQuizResult.jsonResult);
        this.lectureLectureDetailStudentQuizResultModal = lectureLectureDetailStudentQuizResult;
        this.isModalShown = true
    }
    hideModal(): void {
        this.autoShownModal?.hide();
    }
    
    onHidden(): void {
        this.isModalShown = false;
    }

    sendComment(){
        const data = {
            comment: this.commentField,
            lectureDetailId: this.idLectureDetailId
        }
        this.lectureDetailCommentService.addLectureDetailComment(data).subscribe(
            response => {
                if(response){
                    this.toastr.success('Gửi câu hỏi thành công.','Thành công');
                    this.commentField = '';
                    this.ngOnInit();
                } else {
                    this.toastr.error('Có lỗi trong quá trình xử lý','Thất bại');
                }
            }, err => {
                this.toastr.error('Có lỗi trong quá trình xử lý','Thất bại');
            }
        )
    }

    openFormComment(index: number){
        this.hideFormComment[index] = !this.hideFormComment[index];
    }

    sendLectureDetailCommentReply(lectureDetailComment: LectureDetailComment){
        const data = {
            commentReply: lectureDetailComment.commentReply,
            lectureDetailCommentId: lectureDetailComment.id
        }
        this.lectureDetailCommentService.addLectureDetailCommentReply(data).subscribe(
            response => {
                this.toastr.success('Gửi phản hồi thành công.','Thành công');
                this.ngOnInit();
            }, err => {
                this.toastr.error('Có lỗi trong quá trình xử lý','Thất bại');
            }
        )
    }

    getLectureDetailPassed(leanrId){
        this.lectureLectureDetailStudentService.getLectureLectureDetailStudentPassed(leanrId).subscribe(
            response => {
                if(response.error){
                    this.toastr.error(response.errorMessage,'Thất bại');
                } else {
                    this.listLDPassedId = response.resultData;
                    console.log(this.listLDPassedId)
                    this.learn.lecture.map((lecture) => {
                        lecture.lectureDetail.map((lD) => {
                            if(this.listLDPassedId.includes(lD.id)){
                                lD.passedColor = true;
                            }
                        })
                    })
                }
            }, err => {
                this.toastr.error('Có lỗi trong quá trình xử lý','Thất bại');
            }
        )
    }

}